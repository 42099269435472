import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { post, get } from "../api/apiMethods";
import { useFormik } from "formik";
import * as Yup from "yup";
import { LoadingButton } from "@mui/lab";
import Laptop from "../assets/Images/laptop.png";
import towPiece from "../assets/Images/towpiece.png";
import { urls } from "../api/urlConstants";
import NavbarContext from "../context/NavbarContext";
import { RippelVideoButton } from "./RippelVideoButton";
import FormInput from "./FormInput";
import { ToastContainer, toast } from "react-toastify";

const style = {
  height: "100vh",
  width: "100%",
  display: "flex",
};

const leftPart = {
  display: "flex",
  width: { xs: "100%", sm: "100%", md: "50%", lg: "40%" },
  height: "100%",
  backgroundImage: `url(${towPiece})`,
  backgroundRepeat: "no-repeat",
  backgroundPositionY: "100%",
};

const FormStyle = {
  width: { xs: "100%", sm: "100%", md: "500px" },
  // margin: "200px 100px",
};

const rightPart = {
  position: "relative",
  width: { xs: "0", sm: "65%", md: "65%", lg: "60%" },
  display: { xs: "none", sm: "none", md: "block", lg: "block" },
  backgroundImage: `url(${Laptop})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  // backgroundPosition: "center",
  backgroundPositionX: { md: "center", lg: "35px" },
  backgroundPositionY: "center",
  justifyContent: "end",
};

const inputForm = {
  // width: { xs: "100%", sm: "100%", md: "60%" },
  width: { xs: "100%", sm: "100%", md: "80%", lg: "60%" },
  // width:"100%",
  margin: "150px auto",
  padding: "0 20px",
};

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const [formError, setFormError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [submittedEmail, setSubmittedEmail] = useState(false);
  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState("");
  const [header, setHeader] = useState("");
  const [settings, setSettings] = useState(null);
  const [email, setEmail] = useState("");

  const { setShowNavbar } = useContext(NavbarContext);

  useEffect(() => {
    setShowNavbar(false);

    return () => setShowNavbar(true);
  }, []);

  const formik = useFormik({
    initialValues: {
      email: "",
    },

    validationSchema: Yup.object({
      email: Yup.string().email().required().label("Email").max(150),
    }),
    onSubmit: async (values) => {
      setLoading(true);

      try {
        const body = {
          email: values.email,
        };

        const { data, status } = await post(urls.user.resetPasswordLink, body);

        if (status > 199 && status < 299) {
          setLoading(false);
          setHeader("login again with new password");
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    },

    validateOnBlur: false,
  });

  const handleSubmit = async (e) => {
    setLoading(true);

    e.preventDefault();
    try {
      const res = await get(`${urls.user.resetPasswordLink}?email=${email}`);
      if (res.status > 199 && res.status < 299) {
        toast.success(
          "RESET PASSWORD LINK HAS BEEN SUCCESSFULLY SENT TO YOUR EMAIL ADDRESS!"
        );
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <ToastContainer />
      <Box sx={style}>
        <Box sx={leftPart}>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2} sx={inputForm}>
              <Grid item xs={12} md={12}>
                <Typography variant="h5" fontWeight={600} my={2}>
                  Reset Password
                </Typography>
                <Typography
                  variant="body"
                  textAlign="center"
                  sx={{
                    fontSize: {
                      xs: "13px",
                      sm: "14px",
                      md: "16px",
                    },
                  }}
                >
                  Please enter your registered email address!
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <TextField
                  fullWidth
                  formik={formik}
                  id="outlined-basic"
                  name="email"
                  value={email}
                  label="email"
                  variant="outlined"
                  size="small"
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />{" "}
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 2,
                }}
              >
                <LoadingButton
                  type="submit"
                  variant="contained"
                  loading={loading}
                  fullWidth
                >
                  Send Reset Link
                </LoadingButton>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Link to="/" style={{ width: "100%" }}>
                  <Button type="submit" variant="outlined" fullWidth>
                    Back to Login
                  </Button>
                </Link>
              </Grid>
            </Grid>
          </form>
        </Box>

        <Box sx={rightPart}>
          {/* <img src={Laptop} width="100%" height="100%" alt="loading..." /> */}
          <Box
            sx={{
              position: "absolute",
              // width: "20%",
              // background: "yellow",
              margin: "auto",
              top: { md: "40%", lg: "40%" },
              left: { md: "26%", lg: "33%" },
              transform: "rotate(-20deg)",
            }}
          >
            <RippelVideoButton />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ForgotPassword;
