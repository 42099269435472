import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { createContext, useEffect, useState } from "react";
import { Box } from "@mui/material";
import { theme } from "./theme";
import { ThemeProvider } from "@mui/material/styles";
import { ToastContainer } from "react-toastify";

// Custom Components
import Course from "./components/admin/managecourse/Course";
import Invoice from "./components/admin/invoice/Invoice";
import LiveRecorded from "./components/admin/Liverecord/LiveRecorded";
import SubscriberInfo from "./components/admin/subscriber/SubscriberInfo";
import Navbar from "./components/admin/navbar/Navbar";
import CourseDetailsForm from "./components/admin/uploadcourse/CourseDetailsForm";
import Category from "./components/Category";
import Products from "./components/Products";
import AddCourse from "./components/AddCourse";
import Loading from "./components/Loading";
import ResetPassword from "./components/admin/ResetPassword/ResetPassword";
import Coupon from "./components/admin/Coupon";
import DeleteModal from "./components/DeleteModal";
import Login from "./components/Login";
import AccountSetting from "./components/admin/account/accountSetting";
import Live from "./components/Live";

import ForgotPassword from "./components/ForgotPassword";
import ResetPasswordLogin from "./components/ResetPasswordLogin";
import UploadCourse from "./components/uploadcourse/UploadCourse";

// contexts
import NavbarContext from "./context/NavbarContext";
import UserContext from "./context/UserContext";
import PageNotFound from "./components/PageNotFound";

// socket client
import { SocketContext, socket } from "./api/client";
import AddCoupon from "./components/admin/AddCoupon";
import PrivateRoute from "./navigation/PrivateRoute";
import Room from "./components/Room";
import PublicRoute from "./navigation/PublicRoute";

const Data = createContext();

function App() {
  let [state, setState] = useState(false);
  let [updateValue, setUpdateValue] = useState(false);
  const [showNavbar, setShowNavbar] = useState(true);
  const [user, setUser] = useState({ name: "Guest User" });

  useEffect(() => {
    let event = socket.on("connect", () => console.log("socket connected"));

    return () => socket.off(event);
  }, []);

  useEffect(() => {
    if (window.innerWidth < 900) {
      setUpdateValue(true);
    } else {
      setUpdateValue(false);
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 900) {
        setUpdateValue(true);
      } else {
        setUpdateValue(false);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <SocketContext.Provider value={{ socket }}>
        <UserContext.Provider value={{ user, setUser }}>
          <Data.Provider value={{ state, setState }}>
            <NavbarContext.Provider value={{ showNavbar, setShowNavbar }}>
              <ToastContainer />
              <Router>
                {showNavbar ? <Navbar /> : null}
                {/* <Routes>
                 
                </Routes> */}
                <Box
                  sx={{
                    paddingLeft: updateValue || !showNavbar ? "0px" : "170px",
                    paddingTop: showNavbar ? "80px" : 0,
                    position: "relative",
                  }}
                >
                  <Routes>
                    <Route
                      path="/"
                      element={
                        <PublicRoute>
                          <Login />
                        </PublicRoute>
                      }
                    />
                    <Route
                      path="/menu"
                      element={
                        <PrivateRoute>
                          <Category />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/products"
                      element={
                        <PrivateRoute>
                          <Products />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/addCourse"
                      element={
                        <PrivateRoute>
                          <AddCourse />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/upload-courses/adding"
                      element={
                        <PrivateRoute>
                          <UploadCourse />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/manage-courses"
                      element={
                        <PrivateRoute>
                          <Course />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/subscribers-info"
                      element={
                        <PrivateRoute>
                          <SubscriberInfo />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/addcoupon"
                      element={
                        <PrivateRoute>
                          <AddCoupon />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/invoice"
                      element={
                        <PrivateRoute>
                          <Invoice />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/coupon"
                      element={
                        <PrivateRoute>
                          <Coupon />
                        </PrivateRoute>
                      }
                    />

                    <Route
                      path="/account-setting"
                      element={
                        <PrivateRoute>
                          <AccountSetting />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/change-password"
                      element={
                        <PrivateRoute>
                          <ResetPassword />
                        </PrivateRoute>
                      }
                    />

                    <Route
                      path="/forgot-password"
                      element={<ForgotPassword />}
                    />
                    <Route
                      path="/live"
                      element={
                        <PrivateRoute>
                          <Live />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/room/:roomID"
                      element={
                        <PrivateRoute>
                          <Room />
                        </PrivateRoute>
                      }
                    />

                    <Route
                      path="/resetPassword/:access_token"
                      element={<ResetPasswordLogin />}
                    />
                    <Route path="*" element={<PageNotFound />} />
                  </Routes>
                </Box>
              </Router>
            </NavbarContext.Provider>
          </Data.Provider>
        </UserContext.Provider>
      </SocketContext.Provider>
    </ThemeProvider>
  );
}
export default App;
