import {
  Box,
  Button,
  Card,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  TextareaAutosize,
  Typography,
  Collapse,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import cardList from "./CardData";
import { post, put, get, del } from "../../api/apiMethods";
import { urls } from "../../api/urlConstants";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import Checkbox from "@mui/material/Checkbox";
import { useLocation, useNavigate } from "react-router-dom";
import { theme } from "../../theme";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import dayjs from "dayjs";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const AddCoupon = () => {
  const [selectedCard, setSelectedCard] = useState(0);
  const [courseList, setCourseList] = useState([]);
  const [selectedCourses, setSelectedCourse] = useState([]);
  const [selectedDiscountCourses, setSelectedDiscountCourse] = useState([]);
  const handleCardClick = (index) => {
    setSelectedCard(index);
  };
  const [coupanCode, setCoupanCode] = useState("");
  const [selectedMenu, setSelectedMenu] = useState([]);
  const [menuList, setMenuList] = useState("");
  const [selectedDiscountType, setSelectedDiscountType] = useState("");

  const [excludeProducts, setExcludeProducts] = useState([]);
  const [excludeMenu, setExcludeMenu] = useState([]);
  const [dates, setDates] = useState("");
  const [formData, setFormData] = useState({
    notes: "",
    couponAmount: "",
    usagePerCoupan: "",
    usagePerUser: "",
    minimumSpend: "",
    maximumSpend: "",
    email: "",
  });
  const [isChecked, setIsChecked] = useState(false);
  const navigate = useNavigate();
  const [isExpanded, setIsExpanded] = useState(true);
  const location = useLocation();
  const { couponData } = location.state || "";
  const { couponItem } = location.state || "";

  console.log(couponItem, "oooooooooooooooooooo");

  useEffect(() => {
    if (couponItem) {
      setCoupanCode(couponItem.code || "");
      setSelectedDiscountType(couponItem.generalSchema?.coupanType || "");
      setSelectedDiscountCourse(couponItem.generalSchema?.courses || []);
      setFormData({
        ...formData,
        notes: couponItem.notes || "",
        couponAmount: couponItem.generalSchema?.value || "",
        usagePerCoupan: couponItem.usageLimit?.usagePerCoupan || "",
        usagePerUser: couponItem.usageLimit?.usagePerUser || "",
        minimumSpend: couponItem.usageRestriction?.minimumSpend || "",
        maximumSpend: couponItem.usageRestriction?.maximumSpend || "",
        email: couponItem.usageRestriction?.allowedMails || "",
      });
      setDates(couponItem.generalSchema?.expiryDate || "");
      setIsChecked(couponItem.usageRestriction?.individualUseOnly || false);
      setSelectedCourse(
        couponItem.usageRestriction?.products.map((e) => e.courseId || [])
      );
      setExcludeProducts(
        couponItem.usageRestriction?.excludeProducts.map((e) => e.courseId) ||
          []
      );
      setSelectedMenu(
        couponItem.usageRestriction?.productCategories.map((e) => e.menuId) ||
          []
      );
      setExcludeMenu(
        couponItem.usageRestriction?.excludeCategories.map((e) => e.menuId) ||
          []
      );
    }
  }, [couponItem]);

  const handleExpandClick = () => {
    setIsExpanded(!isExpanded);
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleChecked = (e) => {
    setIsChecked(e.target.checked);
  };
  const handleExcludeProductsChange = (e) => {
    setExcludeProducts(e.target.value);
  };

  const handleExcludeMenu = (e) => {
    setExcludeMenu(e.target.value);
  };
  const handleDateChange = (e) => {
    setDates(e.target.value);
  };

  const handleChange = (e) => {
    setSelectedCourse(e.target.value);
  };

  const handleDiscountChange = (e) => {
    setSelectedDiscountCourse(e.target.value);
  };
  const handleChangeMenu = (e) => {
    setSelectedMenu(e.target.value);
  };

  useEffect(() => {
    getCourse();
  }, []);
  console.log(selectedCourses, "sssssssssssssssssssssssss");
  const generateCouponCode = () => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const codeLength = 8;

    let couponCode = "";
    for (let i = 0; i < codeLength; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      couponCode += characters.charAt(randomIndex);
    }
    return couponCode;
  };

  const handleGenerateCoupon = () => {
    const generatedCode = generateCouponCode();
    setCoupanCode(generatedCode);
  };
  const handleChangeCoupan = (e) => {
    setCoupanCode(e.target.value);
  };
  const getCourse = async () => {
    try {
      const data = await get(urls.course.getList);
      setCourseList(data.data.result.data);
      console.log(data.data.result.data, "lllllllllllllll");
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getMenu();
  }, []);

  const getMenu = async () => {
    try {
      const data = await get(urls.menu.getList);
      setMenuList(data.data.result.data);
      console.log(data, "jjjjjjjjjjjj");
    } catch (error) {
      console.log(error);
    }
  };
  console.log(selectedCourses, "selectedCourses");
  const handleCouponSubmit = async (e) => {
    e.preventDefault();
    try {
      if (couponData) {
        const products = [];
        selectedCourses.forEach((courseId) => {
          products.push({ courseId: courseId });
        });

        const excludeProductsdata = [];
        excludeProducts.forEach((courseId) => {
          excludeProductsdata.push({ courseId: courseId });
        });

        const menusData = [];
        selectedMenu.forEach((menuId) => {
          menusData.push({ menuId: menuId });
        });

        const exclusdemenusData = [];
        excludeMenu.forEach((menuId) => {
          exclusdemenusData.push({ menuId: menuId });
        });

        const updatedData = await put(
          `${urls.coupon.editCoupon}${couponData}`,
          {
            code: coupanCode,
            notes: formData.notes,
            generalSchema: {
              coupanType: selectedDiscountType,
              courses: selectedDiscountCourses,
              value: formData.couponAmount,
              expiryDate: dates,
            },
            usageLimit: {
              usagePerCoupan: formData.usagePerCoupan,
              usagePerUser: formData.usagePerUser,
            },
            usageRestriction: {
              minimumSpend: formData.minimumSpend,
              maximumSpend: formData.maximumSpend,
              individualUseOnly: isChecked,
              products: products,
              excludeProducts: excludeProductsdata,
              productCategories: menusData,
              excludeCategories: exclusdemenusData,
              allowedMails: formData.email,
            },
          }
        );
        navigate("/coupon");
        setFormData("");
      } else {
        const data = await post(`${urls.coupon.create}`, {
          code: coupanCode,
          notes: formData.notes,
          generalSchema: {
            coupanType: selectedDiscountType,
            courses: selectedDiscountCourses,
            value: formData.couponAmount,
            expiryDate: dates,
          },
          usageLimit: {
            usagePerCoupan: formData.usagePerCoupan,
            usagePerUser: formData.usagePerUser,
          },
          usageRestriction: {
            minimumSpend: formData.minimumSpend,
            maximumSpend: formData.maximumSpend,
            individualUseOnly: isChecked,
            products: selectedCourses,
            excludeProducts: excludeProducts,
            productCategories: selectedMenu,
            excludeCategories: excludeMenu,
            allowedMails: formData.email,
          },
        });
        navigate("/coupon");
      }
    } catch (error) {
      console.log(error);
    }
  };
  // const handleCouponSubmit = async (e) => {
  //   e.preventDefault();
  //   try {
  //     const body = {
  //       code: coupanCode,
  //       notes: formData.notes,
  //       generalSchema: {
  //         coupanType: selectedDiscountType,
  //         courses: selectedDiscountCourses,
  //         value: formData.couponAmount,
  //         expiryDate: dates,
  //       },
  //       usageLimit: {
  //         usagePerCoupan: formData.usagePerCoupan,
  //         usagePerUser: formData.usagePerUser,
  //       },
  //       usageRestriction: {
  //         minimumSpend: formData.minimumSpend,
  //         maximumSpend: formData.maximumSpend,
  //         individualUseOnly: isChecked,
  //         products: selectedCourses,
  //         excludeProducts: excludeProducts,
  //         productCategories: selectedMenu,
  //         excludeCategories: excludeMenu,
  //         allowedMails: formData.email,
  //       },
  //     };
  //     if (couponData) {
  //       const updatedData = await put(
  //         `${urls.coupon.editCoupon}${couponData._id}`,
  //         body
  //       );
  //       navigate("/coupon");
  //       console.log(updatedData, "Updated Coupon Data");
  //     } else {
  //       const createdData = await post(`${urls.coupon.create}`, body);
  //       console.log(createdData, "Created Coupon Data");
  //     }
  //     navigate("/coupon");
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  return (
    <form onSubmit={handleCouponSubmit}>
      <Box sx={{ margin: "0 15px 0 25px" }}>
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              gap: "15px",
              mb: 1,
              mt: 1,
            }}>
            <Button
              size="medium"
              onClick={() => navigate(-1)}
              startIcon={<ArrowBackIcon />}>
              Back
            </Button>
            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: "20px",
              }}>
              {couponData ? "Update Coupon" : "Add new Coupon"}
            </Typography>
          </Box>

          <Card
            sx={{
              padding: "10px",
              mb: 2,
            }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                // m: 1,
                gap: "10px",
              }}>
              {" "}
              <TextField
                required
                variant="outlined"
                placeholder="coupon code"
                label="coupon code"
                name="value"
                size="small"
                onChange={handleChangeCoupan}
                value={coupanCode}
              />
              <Button
                variant="contained"
                size="medium"
                disableElevation
                color="secondary"
                onClick={handleGenerateCoupon}>
                {couponData ? "Regenerate coupon code" : "Generate coupon code"}
              </Button>
            </Box>

            <TextareaAutosize
              maxRows={4}
              label="Note"
              name="notes"
              placeholder="Description (optional)"
              style={{
                width: "100%",
                maxHeight: 110,
                marginTop: 10,
                padding: 10,
                height: 70,
                borderRadius: "15px",

                "&:focus": {
                  borderColor: theme.palette.grey[200],
                },
                overflow: "auto",
              }}
              value={formData.notes}
              onChange={handleFormChange}
            />
          </Card>
        </Box>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}>
                    <Typography variant="h6">Coupon Data</Typography>
                    <Button onClick={handleExpandClick}>
                      {/* <ArrowDropUpIcon
                        fontSize="medium"
                        sx={{ color: "black" }}
                      /> */}
                      {isExpanded ? (
                        <ArrowDropDownIcon
                          fontSize="medium"
                          sx={{ color: "black" }}
                        />
                      ) : (
                        <ArrowDropUpIcon
                          fontSize="medium"
                          sx={{ color: "black" }}
                        />
                      )}
                    </Button>
                  </Box>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                <TableRow>
                  <TableCell>
                    <Grid
                      conatiner
                      spacing={1}
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        flexDirection: "row",
                        gap: "10px",
                      }}>
                      {/* <Grid item xs={12} md={4} lg={4}>
          {cardList.map((data, i) => (
            <Card
              sx={{
                padding: "20px",
                width: "210px",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                marginBottom: "5px",
                backgroundColor:
                  selectedCard === i ? theme.palette.secondary.light : "white",
              }}
              onClick={() => handleCardClick(i)}
              isselected={selectedCard === i ? "true" : undefined}
            >
              {data.icon}
              <Typography>{data.title}</Typography>
            </Card>
          ))}
        </Grid> */}
                      <Grid
                        item
                        xs={12}
                        md={4}
                        lg={4}
                        style={{ backgroundColor: "#f5f5f5" }}>
                        <List>
                          {cardList.map((data, i) => (
                            <ListItem
                              key={i}
                              sx={{
                                padding: "20px",
                                width: "250px",
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                marginBottom: "5px",
                                backgroundColor:
                                  selectedCard === i
                                    ? "rgba(0, 153, 116, 0.08)"
                                    : "wheate",
                              }}
                              onClick={() => handleCardClick(i)}
                              isselected={selectedCard === i}>
                              {data.icon}
                              <ListItemText>
                                <Typography>{data.title}</Typography>
                              </ListItemText>
                            </ListItem>
                          ))}
                        </List>
                      </Grid>
                      <Grid item xs={12} md={8} lg={8}>
                        {selectedCard !== null && (
                          <Card>
                            {selectedCard === 0 && (
                              <>
                                <Grid
                                  conatiner
                                  sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    flexDirection: "column",
                                  }}>
                                  <Grid
                                    container
                                    spacing={1}
                                    sx={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      flexDirection: "row",
                                      m: 1,
                                    }}>
                                    <Grid item xs={12} md={4} lg={3}>
                                      <Typography>Discount Type:</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={8} lg={8}>
                                      <FormControl
                                        required
                                        sx={{ m: 1, minWidth: 227 }}
                                        size="small">
                                        <InputLabel>
                                          select discount type
                                        </InputLabel>
                                        <Select
                                          id="demo-select-small"
                                          label=" select discount type"
                                          value={selectedDiscountType}
                                          onChange={(e) =>
                                            setSelectedDiscountType(
                                              e.target.value
                                            )
                                          }>
                                          <MenuItem value={"Percentage"}>
                                            Percentage discount
                                          </MenuItem>
                                          <MenuItem value={"Flat"}>
                                            Fixed Cart discount
                                          </MenuItem>
                                          <MenuItem
                                            value={"FixedProductDiscount"}>
                                            Fixed Product discount
                                          </MenuItem>
                                        </Select>
                                        {selectedCard === 0 &&
                                          selectedDiscountType ===
                                            "FixedProductDiscount" && (
                                            <>
                                              <Grid
                                                item
                                                xs={12}
                                                md={8}
                                                lg={8}
                                                sx={{ mt: 1 }}>
                                                <FormControl
                                                  sx={{ m: 1, minWidth: 227 }}
                                                  size="small">
                                                  <InputLabel>
                                                    select course
                                                  </InputLabel>
                                                  <Select
                                                    label="select course"
                                                    labelId="demo-multiple-select-label"
                                                    id="demo-multiple-select"
                                                    multiple
                                                    sx={{
                                                      minWidth: {
                                                        md: 227,
                                                        xs: "100%",
                                                      },
                                                    }}
                                                    name="selectedDiscountCourses"
                                                    value={
                                                      selectedDiscountCourses
                                                    }
                                                    onChange={
                                                      handleDiscountChange
                                                    }>
                                                    {courseList.map((data) => (
                                                      <MenuItem
                                                        value={data._id}
                                                        key={data._id}>
                                                        {data.title}
                                                      </MenuItem>
                                                    ))}
                                                  </Select>
                                                </FormControl>
                                              </Grid>
                                            </>
                                          )}
                                      </FormControl>
                                    </Grid>

                                    <Grid item xs={12} md={4} lg={3}>
                                      <Typography>Coupon Amount:</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={8} lg={8}>
                                      <FormControl
                                        sx={{ m: 1, minWidth: 227 }}
                                        size="small">
                                        <TextField
                                          required
                                          variant="outlined"
                                          placeholder="Coupon Amount"
                                          label="Coupon Amount"
                                          name="couponAmount"
                                          size="small"
                                          value={formData.couponAmount}
                                          onChange={handleFormChange}
                                          sx={{
                                            minWidth: { md: 227, xs: "100%" },
                                          }}
                                        />
                                      </FormControl>
                                    </Grid>

                                    <Grid item xs={12} md={4} lg={3}>
                                      <Typography>
                                        Coupon expiry date:
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={8} lg={8}>
                                      <FormControl
                                        sx={{ m: 1, minWidth: 227 }}
                                        size="small">
                                        <TextField
                                          size="small"
                                          name="expiryDate"
                                          type="date"
                                          // value={dates}
                                          value={dayjs(dates).format(
                                            "YYYY-MM-DD"
                                          )}
                                          sx={{
                                            minWidth: { md: 227, xs: "100%" },
                                          }}
                                          onChange={handleDateChange}
                                        />
                                      </FormControl>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </>
                            )}
                            {selectedCard === 1 && (
                              <>
                                <Grid
                                  conatiner
                                  sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    flexDirection: "column",
                                  }}>
                                  <Grid
                                    container
                                    spacing={1}
                                    sx={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      flexDirection: "row",
                                      m: 1,
                                    }}>
                                    <Grid item xs={12} md={4} lg={3}>
                                      <Typography>Minimum spend:</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={8} lg={8}>
                                      <FormControl
                                        sx={{ m: 1, minWidth: 120 }}
                                        size="small">
                                        <TextField
                                          type="number"
                                          placeholder="Minimum spend"
                                          name="minimumSpend"
                                          size="small"
                                          value={formData.minimumSpend}
                                          onChange={handleFormChange}
                                        />
                                      </FormControl>
                                    </Grid>

                                    <Grid item xs={12} md={4} lg={3}>
                                      <Typography>Maximum spend:</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={8} lg={8}>
                                      <FormControl
                                        sx={{ m: 1, minWidth: 120 }}
                                        size="small">
                                        <TextField
                                          type="number"
                                          placeholder="Maximum spend"
                                          name="maximumSpend"
                                          size="small"
                                          value={formData.maximumSpend}
                                          onChange={handleFormChange}
                                        />
                                      </FormControl>
                                    </Grid>

                                    <Grid item xs={12} md={4} lg={3}>
                                      <Typography>
                                        Individual Use Only:
                                      </Typography>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={12}
                                      md={8}
                                      lg={8}
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}>
                                      <Checkbox
                                        checked={isChecked}
                                        onChange={handleChecked}
                                        {...label}
                                      />
                                      <Typography>
                                        Check this box if the coupon cannot be
                                        used in conjunction with other coupons.
                                      </Typography>
                                    </Grid>

                                    <Grid item xs={12} md={12} lg={12}>
                                      <Divider
                                        sx={{
                                          backgroundColor: "gray",
                                        }}
                                      />
                                    </Grid>

                                    <Grid item xs={12} md={4} lg={3}>
                                      <Typography>Course:</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={8} lg={8}>
                                      <FormControl
                                        sx={{ m: 1, minWidth: 227 }}
                                        size="small">
                                        <InputLabel>select course</InputLabel>
                                        <Select
                                          label="select course"
                                          labelId="demo-multiple-select-label"
                                          id="demo-multiple-select"
                                          multiple
                                          name="selectedCourses"
                                          value={selectedCourses}
                                          onChange={handleChange}>
                                          {courseList.map((data) => (
                                            <MenuItem
                                              value={data._id}
                                              key={data._id}>
                                              {data.title}
                                            </MenuItem>
                                          ))}
                                        </Select>
                                      </FormControl>
                                    </Grid>

                                    <Grid item xs={12} md={4} lg={3}>
                                      <Typography>Exclude Courses:</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={8} lg={8}>
                                      <FormControl
                                        sx={{ m: 1, minWidth: 227 }}
                                        size="small">
                                        <InputLabel>select course</InputLabel>
                                        <Select
                                          label="select course"
                                          labelId="demo-multiple-select-label"
                                          id="demo-multiple-select"
                                          multiple
                                          name="excludeProducts"
                                          value={excludeProducts}
                                          onChange={
                                            handleExcludeProductsChange
                                          }>
                                          {courseList
                                            .filter(
                                              (data) =>
                                                !selectedCourses.includes(
                                                  data._id
                                                )
                                            )
                                            .map((data) => (
                                              <MenuItem
                                                value={data._id}
                                                key={data._id}>
                                                {data.title}
                                              </MenuItem>
                                            ))}
                                        </Select>
                                      </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={12} lg={12}>
                                      <Divider
                                        sx={{
                                          backgroundColor: "gray",
                                        }}
                                      />
                                    </Grid>

                                    <Grid item xs={12} md={4} lg={3}>
                                      {" "}
                                      <Typography>Catagory:</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={8} lg={8}>
                                      <FormControl
                                        sx={{ m: 1, minWidth: 227 }}
                                        size="small">
                                        <InputLabel>select catagory</InputLabel>
                                        <Select
                                          label="select catagory"
                                          labelId="demo-multiple-select-label"
                                          id="demo-multiple-select"
                                          multiple
                                          value={selectedMenu}
                                          onChange={handleChangeMenu}>
                                          {menuList?.map((menuList) => (
                                            <MenuItem
                                              key={menuList._id}
                                              value={menuList._id}>
                                              {menuList.name}
                                            </MenuItem>
                                          ))}
                                        </Select>
                                      </FormControl>
                                    </Grid>

                                    <Grid item xs={12} md={4} lg={3}>
                                      <Typography>
                                        {" "}
                                        Exclude categories:
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={8} lg={8}>
                                      <FormControl
                                        sx={{ m: 1, minWidth: 227 }}
                                        size="small">
                                        <InputLabel>select catagory</InputLabel>
                                        <Select
                                          label="select catagory"
                                          labelId="demo-multiple-select-label"
                                          id="demo-multiple-select"
                                          multiple
                                          value={excludeMenu}
                                          onChange={handleExcludeMenu}
                                          size="small">
                                          {menuList
                                            .filter(
                                              (data) =>
                                                !selectedMenu.includes(data._id)
                                            )
                                            .map((menuList) => (
                                              <MenuItem
                                                key={menuList._id}
                                                value={menuList._id}>
                                                {menuList.name}
                                              </MenuItem>
                                            ))}
                                        </Select>
                                      </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={12} lg={12}>
                                      <Divider
                                        sx={{
                                          backgroundColor: "gray",
                                        }}
                                      />
                                    </Grid>

                                    <Grid item xs={12} md={4} lg={3}>
                                      <Typography>Allowed emails:</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={8} lg={8}>
                                      <FormControl sx={{ m: 1 }} size="small">
                                        <TextField
                                          type="string"
                                          placeholder="Allowed emails"
                                          name="email"
                                          size="small"
                                          value={formData.email}
                                          onChange={handleFormChange}
                                        />
                                      </FormControl>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </>
                            )}
                            {selectedCard === 2 && (
                              <>
                                <Grid
                                  conatiner
                                  sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    flexDirection: "column",
                                  }}>
                                  <Grid
                                    container
                                    spacing={1}
                                    sx={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      flexDirection: "row",
                                      m: 1,
                                    }}>
                                    <Grid item xs={12} md={4} lg={3}>
                                      <Typography>
                                        Usage limit per coupon:
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={8} lg={8}>
                                      <FormControl
                                        sx={{ m: 1, minWidth: 120 }}
                                        size="small">
                                        <TextField
                                          type="number"
                                          placeholder="Unlimited Usage"
                                          name="usagePerCoupan"
                                          size="small"
                                          value={formData.usagePerCoupan}
                                          onChange={handleFormChange}
                                        />
                                      </FormControl>
                                    </Grid>

                                    <Grid item xs={12} md={4} lg={3}>
                                      <Typography>
                                        Usage limit per user:
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={8} lg={8}>
                                      <FormControl
                                        sx={{ m: 1, minWidth: 120 }}
                                        size="small">
                                        <TextField
                                          type="number"
                                          placeholder="Unlimited Usage"
                                          name="usagePerUser"
                                          size="small"
                                          value={formData.usagePerUser}
                                          onChange={handleFormChange}
                                        />
                                      </FormControl>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </>
                            )}

                            {/* <Box
                              sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                                alignItems: "center",
                                m: 1,
                              }}
                            >
                              {" "}
                              <Button
                                variant="contained"
                                type="submit"
                                // onClick={handleCouponSubmit}
                              >
                                Submit
                              </Button>
                            </Box> */}
                          </Card>
                        )}
                      </Grid>{" "}
                    </Grid>
                  </TableCell>
                </TableRow>
                <Button
                  variant="contained"
                  type="submit"
                  sx={{ m: 1 }}
                  // onClick={handleCouponSubmit}
                >
                  {couponData ? "Update" : "Add"}
                </Button>
              </Collapse>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </form>
  );
};
export default AddCoupon;
