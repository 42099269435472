import React from "react";
import { Navigate } from "react-router-dom";

const PublicRoute = ({ children }) => {
  const isAuth = window.localStorage.getItem("user");

  const navUser = () => {
    return <Navigate to="/menu" />;
  };
  return isAuth ? navUser() : children;
};
export default PublicRoute;
