import React from "react";
import { Box, Button, Fab, Paper } from "@mui/material";
import PlayCircleRoundedIcon from "@mui/icons-material/PlayCircleRounded";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";

export const RippelVideoButton = () => {
  return (
    <>
      <Fab
        color="primary"
        className="ripple"
        aria-label="add"
        sx={{
          fontSize: 60,
          background: "#C38EDE",
          "&:hover": { background: "#C38EDE" },
        }}
      >
        <PlayArrowIcon />
      </Fab>
    </>
  );
};
