import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Container,
  Grid,
  TextField,
  Typography,
  Box,
  styled,
  Stack,
  Paper,
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import { LoadingButton } from "@mui/lab";
import { theme } from "../../../theme";

// apis
import { put } from "../../../api/apiMethods";
import { urls } from "../../../api/urlConstants";

// context
import UserContext from "../../../context/UserContext";

const Expand = styled(TextField)(({ theme }) => ({
  marginTop: "2px",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

const Label = styled("label")(() => ({
  paddingLeft: "10px",
  fontWeight: 400,
}));

const AccountSetting = () => {
  const { user, setUser } = useContext(UserContext);

  useEffect(() => {
    let user = window.localStorage.getItem("user");
    if (user) {
      setUser(JSON.parse(user));
      setFormData(JSON.parse(user));
    }
  }, []);

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const [formData, setFormData] = useState({
    fullname: "",
    email: "",
    contactno: "",
    city: "",
    state: "",
    address: "",
  });

  const [selectedFile, setSelectedFile] = useState(null);

  const fileInputRef = useRef(null);
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file);
    }
  };

  const handlePhotoClick = () => {
    fileInputRef.current.click();
  };

  useEffect(() => {
    if (user) {
      setFormData({
        fullname: user.name || user.fullname,
        email: user.email?.address || user.email,
        contactno: user.mobile || user.contactno,
        city: user.address?.city || user.city,
        state: user.address?.state || user.state,
        address: user.address?.addressLineOne || user.address?.addressLineOne,
      });
    }
  }, [user]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const body = {
        name: formData.fullname,
        address: formData.address,
        contactNumber: formData.contactno,
        city: formData.city,
        state: formData.state,
      };
      const formdata = new FormData();
      formdata.append("bodyData", JSON.stringify(body));
      formdata.append("file", selectedFile);
      const res = await put(`${urls.user.updateInfo}`, formdata);
      if (res) {
        window.localStorage.setItem("user", JSON.stringify(res.data.result));
        setUser(res.data.result);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
    setData((prevData) => [...prevData, formData]);

    handleClose();
  };

  return (
    <>
      <Container>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mt: 2,
          }}
        >
          <Paper sx={{ maxWidth: 800, px: 3 }}>
            <form onSubmit={handleSubmit} style={{ marginTop: "10px" }}>
              <Grid container spacing={1}>
                <Grid
                  item
                  xs={12}
                  md={12}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "auto",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      my: 2,
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: 700,
                        fontSize: "25px",
                        whiteSpace: "nowrap",
                        marginLeft: "17px",
                        marginBottom: "20px",
                        color: `${theme.palette.primary.main}`,
                      }}
                    >
                      Account Settings
                    </Typography>

                    <Stack
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        textAlign: "center",
                        alignItems: "center",
                      }}
                    >
                      <Avatar
                        sx={{
                          fontSize: "3.5rem",
                          height: "85px",
                          width: "85px",
                          borderRadius: "50%",
                          "&:hover": {
                            height: "85px",
                            width: "85px",
                            borderRadius: "50%",
                          },
                        }}
                        alt="profile pic"
                        src={
                          selectedFile
                            ? URL.createObjectURL(selectedFile)
                            : user?.image
                        }
                      />
                      <Typography
                        sx={{
                          fontSize: "12px",
                          fontWeight: 800,
                          color: `${theme.palette.primary.main}`,
                          marginTop: "8px",
                          cursor: "pointer",
                        }}
                        onClick={handlePhotoClick}
                      >
                        Change Photo
                      </Typography>
                    </Stack>

                    {/* Hidden file input */}
                    <input
                      type="file"
                      accept="image/*"
                      style={{ display: "none" }}
                      ref={fileInputRef}
                      onChange={handleFileChange}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Label htmlFor="">Full Name</Label>
                  <br />
                  <Expand
                    name="fullname"
                    value={formData.fullname}
                    size="small"
                    required
                    fullWidth
                    placeholder="Mherab"
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Label htmlFor="">Email</Label>
                  <br />
                  <Expand
                    disabled={true}
                    name="email"
                    position="relative"
                    size="small"
                    type={"emial"}
                    value={formData.email}
                    fullWidth
                    placeholder="MehrabBozorgi.Business@gmail.com"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Label htmlFor="">Contact Number</Label>
                  <br />
                  <Expand
                    inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                    name="contactno"
                    size="small"
                    type=""
                    value={formData.contactno}
                    required
                    fullWidth
                    placeholder=""
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Label htmlFor="">State</Label>
                  <br />
                  <Expand
                    name="state"
                    value={formData.state}
                    size="small"
                    fullWidth
                    placeholder="Karnataka"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Label htmlFor="">City</Label>
                  <br />
                  <Expand
                    name="city"
                    size="small"
                    value={formData.city}
                    fullWidth
                    placeholder="Channapatna"
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Label htmlFor="">Address</Label>
                  <br />
                  <Expand
                    name="address"
                    size="small"
                    value={formData.address}
                    fullWidth
                    placeholder="3306 Zbonack isle"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  sx={{ display: "flex", margin: "20px 0" }}
                >
                  <LoadingButton
                    variant="contained"
                    type="submit"
                    loading={loading}
                  >
                    Update
                  </LoadingButton>
                </Grid>
              </Grid>
            </form>
          </Paper>
        </Box>
      </Container>
    </>
  );
};

export default AccountSetting;
