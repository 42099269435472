import { useState, useEffect } from "react";
import {
  Button,
  Grid,
  Typography,
  Box,
  styled,
  TextField,
  Tooltip,
  Divider,
  Dialog,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import Select from "react-select";
import { LoadingButton } from "@mui/lab";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
// custom imports
import ShowUploadTable from "./ShowUploadTable";

//Icons
import FilterListIcon from "@mui/icons-material/FilterList";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ClearIcon from "@mui/icons-material/Clear";

// apis
import { get, post } from "../../api/apiMethods";
import { urls } from "../../api/urlConstants";
import { theme } from "../../theme";

const Label = styled("label")(() => ({
  fontWeight: "bold",
  fontSize: "14px",
  paddingLeft: "10px",
  color: "black",
  "@media (max-width: 768px)": {
    fontSize: "12px",
    paddingLeft: "5px",
  },
}));

const DownloadBox = styled(Box)(() => ({
  background: `rgb(133 140 223 / 19%)`,
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  borderRadius: "10px",
  color: `${theme.palette.primary.main}`,
}));

export default function UploadCourse() {
  const theme = useTheme();
  const { state } = useLocation();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [courseDetails, setCourseDetails] = useState({ chapters: [] });
  const [courseName, setCourseName] = useState("");
  const [creatingChapter, setCreatingChapter] = useState(false);
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [showFileInput, setShowFileInput] = useState(false);
  const location = useLocation();
  const [list, setList] = useState([]);
  const [openChapter, setOpenChapter] = useState(false);
  const [chapterName, setChapterName] = useState("");
  const [selectedCourseId, setSelectedCourseId] = useState(
    state ? state.selectedCourseIds : ""
  );
  const [chapterDescription, setChapterDescription] = useState();

  const createChapter = async (e) => {
    e.preventDefault();
    setCreatingChapter(true);

    try {
      if (!chapterName) {
        return toast.error("Please mention Chapter Name");
      }
      const body = { title: chapterName };
      const formsData = new FormData();
      formsData.append("body", JSON.stringify(chapterName));

      formsData.append("file", file);

      const { data } = await post(
        `${urls.courseContent.createChapter}/${selectedCourseId}`,
        formsData
      );

      setCourseDetails(data.result);
      setChapterName("");

      setOpenChapter(false);
      getCourseDetails();
      setFile(null);
      setFileName("");
      setChapterDescription("");
      setCreatingChapter(false);
    } catch (error) {
      setCreatingChapter(false);
      console.log(error);
    }
  };

  const getCourse = async () => {
    try {
      const { data, status } = await get(`${urls?.course?.getList}`);
      // console.log(data, "----0000");
      setList(
        data?.result?.data?.map((m) => ({ label: m?.title, value: m?._id }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCourse();
  }, []);

  const handleChangeCourse = (e) => {
    setSelectedCourseId(e.value);
  };

  const handleOpenChapter = () => setOpenChapter(true);

  const handleChapterClose = () => {
    setOpenChapter(false);
    setFileName("");
    setChapterName("");
    setFile(null);
  };

  const getCourseDetails = async () => {
    try {
      const res = await get(
        `${urls.courseContent.getCourseContent}/${selectedCourseId}`
      );
      setCourseDetails(res.data.result);
    } catch (error) {
      console.error(error);
    }
  };
  const getCourseName = async () => {
    try {
      const res = await get(`${urls.course.getOnevalue}/${selectedCourseId}`);
      setCourseName(res.data.result.data.title);
      console.log(res.data.result.data.title, "=-=-=");
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    if (selectedCourseId) {
      getCourseDetails();
      getCourseName();
    }
  }, [selectedCourseId]);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    setFileName(selectedFile.name);
  };

  const handleClickOpen = () => {
    setShowFileInput(true);
  };

  const handleRemoveFileClick = () => {
    setFile(null);
    setFileName("");
  };

  return (
    <>
      <Box ml={2} p={2}>
        <Grid
          container
          sx={{
            p: 1,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            my={2}
            gap={2}
            display="flex"
            alignItems="center"
          >
            <Box>
              <Label htmlFor="">Select Course To Add Content</Label>
              <Select
                name="title"
                type="text"
                options={list}
                value={list.find((m) => m.value === selectedCourseId) || ""}
                onChange={handleChangeCourse}
                styles={{
                  container: (provided, state) => ({
                    ...provided,
                    marginBottom: "2px",
                  }),
                  menu: (provided, state) => ({
                    ...provided,
                    zIndex: 20,
                  }),

                  control: (provided, state) => ({
                    ...provided,
                    borderRadius: "25px",
                  }),
                }}
              />
            </Box>

            <Box sx={{ width: "50%", marginTop: 3 }}>
              <Tooltip title="Add chapter">
                <Button
                  variant="contained"
                  size="medium"
                  disabled={!selectedCourseId}
                  color="secondary"
                  startIcon={<AddIcon />}
                  onClick={handleOpenChapter}
                >
                  Chapter
                </Button>
              </Tooltip>
            </Box>
          </Grid>
        </Grid>
        <Divider />
        {courseDetails.chapters?.map((chapter, i) => (
          <ShowUploadTable
            updateList={getCourseDetails}
            key={i}
            chapter={chapter}
            course={courseDetails}
          />
        ))}
      </Box>

      {/* chapter model --------------- */}
      <Dialog
        fullScreen={fullScreen}
        open={openChapter}
        onClose={handleChapterClose}
        aria-labelledby="responsive-dialog-title"
      >
        <form onSubmit={createChapter}>
          <Grid container rowSpacing={2} p={2}>
            <Grid item xs={12} md={12}>
              <Typography
                sx={{
                  textAlign: "center",
                  fontWeight: 600,
                  fontSize: "18px",
                }}
              >
                Add Chapter for Course: {courseName}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              sx={{
                textAlign: "center",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                columnGap: 2,
              }}
            >
              <TextField
                fullWidth
                size="small"
                name="title"
                required
                value={chapterName}
                placeholder="Enter Chapter Name"
                onChange={(e) => setChapterName(e.target.value)}
              />

              <Tooltip title="Upload Material">
                <Button
                  component="label"
                  variant="outlined"
                  fullWidth
                  sx={{
                    padding: "7px",
                    maxWidth: 200,
                  }}
                  startIcon={<FileUploadIcon fontSize="small" />}
                  size="small"
                >
                  upload material
                  <input
                    hidden
                    type="file"
                    onChange={handleFileChange}
                    style={{ display: "block" }}
                  />
                </Button>
              </Tooltip>
            </Grid>
            <Grid item xs={12} md={12}>
              {fileName ? (
                <DownloadBox px={2} py={1}>
                  <Typography>{fileName}</Typography>
                  <Button onClick={handleRemoveFileClick} color="error">
                    <ClearIcon />
                  </Button>
                </DownloadBox>
              ) : (
                ""
              )}
            </Grid>

            <Grid
              sx={{
                display: "flex",
                width: "100%",
                flexDirection: "column",
                justifyContent: "center",
              }}
              item
              xs={12}
              md={12}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: 2,
                  alignSelf: "center",
                  flex: 1,
                  width: "100%",
                }}
              >
                <Button
                  variant="outlined"
                  color="error"
                  onClick={handleChapterClose}
                  sx={{ width: "45%" }}
                >
                  Cancel
                </Button>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  sx={{
                    width: "45%",
                  }}
                  loading={creatingChapter}
                >
                  Submit
                </LoadingButton>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Dialog>
    </>
  );
}
