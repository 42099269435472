import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import { Link, useNavigate } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import { del, get, post, put } from "../api/apiMethods";
import { urls } from "../api/urlConstants";

// icons
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";

import dayjs, { Dayjs } from "dayjs";
import copy from "clipboard-copy";
import moment from "moment";

const getDateWithTime = (dateTimeString = "27/09/2024 14:30") => {
  const dateTimeComponents = dateTimeString.split(" ");

  // Extract date components
  const dateComponents = dateTimeComponents[0].split("/");
  const yearExtracted = parseInt(dateComponents[2], 10);
  const monthExtracted = parseInt(dateComponents[1], 10) - 1; // Months are zero-based
  const dayExtracted = parseInt(dateComponents[0], 10);

  // Extract time components
  const timeComponents = dateTimeComponents[1].split(":");
  const hoursExtracted = parseInt(timeComponents[0], 10);
  const minutesExtracted = parseInt(timeComponents[1], 10);

  // Create a new Date object using the extracted components
  return new Date(
    yearExtracted,
    monthExtracted,
    dayExtracted,
    hoursExtracted,
    minutesExtracted
  );
};

const getStatus = (startDate, startTime, expiryDate, expiryTime) => {
  let currentDate = Date.now();
  let startDataTimestring = `${moment(startDate).format(
    "DD/MM/YYYY"
  )} ${startTime}}`;
  let expiryDateTimeString = `${moment(expiryDate).format(
    "DD/MM/YYYY"
  )} ${expiryTime}`;
  let currentDateTimeString = `${moment(currentDate).format(
    "DD/MM/YYYY"
  )} ${moment(currentDate).format("LT")}`;

  let meetingDate = getDateWithTime(startDataTimestring);
  let meetingExpiryDate = getDateWithTime(expiryDateTimeString);
  let currentDateAndTime = getDateWithTime(currentDateTimeString);

  if (meetingDate > currentDateAndTime) {
    return "Upcoming";
  } else if (
    meetingDate < currentDateAndTime &&
    meetingExpiryDate > currentDateAndTime
  ) {
    return "Available";
  } else {
    return "Expired";
  }
};

const TableData = styled(TableCell)(() => ({
  fontSize: "15px",
  fontWeight: "bold",
  textAlign: "center",
}));

const Td = styled(TableCell)(() => ({
  textAlign: "center",
}));
const Label = styled("label")(() => ({
  fontWeight: 650,
  fontSize: "15px",
  color: "#424242",
  paddingLeft: "10px",
}));
const style = {
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
};

const defaultLiveData = {
  course: "",
  courseDate: "",
  startTime: "",
  expiryDate: "",
  expiryTime: "",
  meetingType: "",
  participantType: "",
  participants: [],
};

const Live = () => {
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [editId, setEditId] = useState("");
  const [data, setData] = useState([]);
  const [copyMeetingList, setCopyMeetingList] = useState("");
  const [courseList, setCourseList] = useState([]);
  const [allMeetingList, setAllMeetingList] = useState([]);
  const [liveData, setLiveData] = useState(defaultLiveData);
  const [selectedCourse, setSelectedMeeting] = useState("All");
  const [filterMeeting, setFilterMeeting] = useState([]);
  const [menuList, setMenuList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [deleteMeetingItem, setDeleteMeetingItem] = useState({
    itemToDelete: "",
    open: false,
  });

  // ==================== Pageimation start ============================
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  // ====================== Pageimation end ==========================

  // console.log(selectedCourse, "--selectedCourse");
  // console.log(filterMeeting, "---filterMeeting");

  const handleChangeCourseList = (e) => {
    setLiveData({ ...liveData, course: e.target.value });
  };

  const handleFilter = (e) => {
    setSelectedMeeting(e.target.value);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLiveData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Fileter meeting
  useEffect(() => {
    if (selectedCourse) {
      if (selectedCourse === "All") {
        setFilterMeeting(allMeetingList);
      } else {
        setFilterMeeting(
          allMeetingList.filter((c) => c.meetingType === selectedCourse)
        );
      }
    }
  }, [selectedCourse]);

  // Get meeting list
  const getLiveMeetingHandle = async () => {
    try {
      const { data, status } = await get(`${urls.live.getAllMeetings}`);
      setAllMeetingList(data.result);
      setFilterMeeting(data?.result);
      const getMenu = data.result.map((c) => c.courseId.title);
      const uniqueMenu = [...new Set(getMenu)];
      setMenuList(["All", ...uniqueMenu]);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const body = {
        courseId: liveData.course,
        startTime: liveData.startTime,
        startDate: liveData.courseDate,
        expiryDate: liveData.expiryDate,
        expiryTime: liveData.expiryTime,
        meetingType: liveData.meetingType,
        participantType: liveData.participantType,
        participants:
          liveData.participantType === "Single"
            ? [liveData.participants]
            : liveData.participants,
      };

      console.log(body, "===hh");

      if (!editId) {
        const response = await post(`${urls.live.create}`, body);

        setData((pre) => [...pre, liveData]);
        setLiveData(defaultLiveData);
      } else {
        const { data, status } = await put(
          `${urls.live.updateMeeting}/${editId}`,
          body
        );

        setLiveData(defaultLiveData);
        setEditId("");
      }
      setOpen(false);
      setLiveData(defaultLiveData);
      getLiveMeetingHandle();
    } catch (error) {
      console.error();
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
    setLiveData((pre) => ({
      ...pre,
      participantType: "Who are enrolled in this course",
    }));
    setEditId("");
  };

  // update meeting
  const handleupdateModelOpen = (id) => {
    setEditId(id);
    let data = allMeetingList.filter((m) => m._id == id)[0];
    console.log(data, "==fff");
    setLiveData({
      course:
        data.participantType === "Who are enrolled in this course"
          ? data.courseId._id
          : "",
      courseDate: dayjs(data.startDate).format("YYYY-MM-DD"),
      expiryDate: dayjs(data.expiryDate).format("YYYY-MM-DD"),
      startTime: data.startTime,
      expiryTime: data.expiryTime,
      meetingType: data.meetingType,
      participantType: data.participantType,
      participants: data.participants,
    });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setLiveData(defaultLiveData);
  };

  const handleJoinClick = (meeting) => {
    navigate(`/room/${meeting.roomId}`, {
      state: { meetingType: meeting.meetingType },
    });
  };

  // Get Course handle
  const handleGetCourse = async () => {
    try {
      const { data, status } = await get(`${urls?.course?.getList}`);

      setCourseList(data?.result?.data);
      setLiveData((prev) => ({
        ...prev,
        course: data.result.data[0]?._id,
      }));
    } catch (error) {
      console.log(error);
    }
  };

  // Get User
  const handleGetUsers = async () => {
    try {
      const { data, status } = await get(`${urls.user.getSubscriberList}`);
      // console.log(data.result.data, "000");
      setUserList(data.result.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    handleGetCourse();
    getLiveMeetingHandle();
    handleGetUsers();
    getDateWithTime();
  }, []);

  useEffect(() => {
    if (liveData.meetingType === "OneONoneCall") {
      setLiveData((pre) => ({ ...pre, participantType: "Single" }));
    }
  }, [liveData.meetingType]);
  console.log(liveData, "-live");

  useEffect(() => {
    if (liveData.participantType === "Who are enrolled in this course") {
      setLiveData((pre) => ({
        ...pre,
        course: liveData.course ? liveData.course : courseList[0]?._id,
      }));
    }
  }, [liveData.participantType]);

  const handleCopyLink = async (meetingLink) => {
    // console.log(meetingLink, "===meetingLink");
    copy(`${meetingLink}`);
  };

  const handleCloseDeleteModal = () => {
    setDeleteMeetingItem({ open: false, itemToDelete: "" });
  };

  const handleDeleteMeeting = async (id) => {
    console.log(deleteMeetingItem.itemToDelete, "itemToDelete");
    try {
      const response = await put(
        `${urls.live.deleteMeeting}/${deleteMeetingItem.itemToDelete}`
      );

      setDeleteMeetingItem({ open: false, itemToDelete: "" });
      getLiveMeetingHandle();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Box px={4}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "10px",
            mb: 1,
          }}
        >
          <FormControl size="small" sx={{ m: 1, minWidth: 250 }}>
            <Label id="demo-simple-select-label">
              <FilterListIcon fontSize="small" /> Filter Meeting
            </Label>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              placeholder="Select Course"
              value={selectedCourse}
              onChange={handleFilter}
            >
              <MenuItem value="All"> All </MenuItem>
              <MenuItem value="OneONoneCall">One-On-One Call </MenuItem>
              <MenuItem value="GroupCall">Group Call </MenuItem>
              <MenuItem value="LiveStreaming">Live Stream </MenuItem>
            </Select>
          </FormControl>
          <Tooltip title="Create">
            <Button
              onClick={handleClickOpen}
              sx={{
                p: 1,
              }}
              size="small"
              variant="contained"
            >
              Create
            </Button>
          </Tooltip>
        </Box>

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableData sx={{ fontWeight: 600 }}>S.No</TableData>
                {/* <TableData sx={{ fontWeight: 600 }}>Course</TableData> */}
                <TableData sx={{ fontWeight: 600 }}>Meeting Date</TableData>
                <TableData sx={{ fontWeight: 600 }}>Meeting Type</TableData>
                {/* <TableData sx={{ fontWeight: 600 }}>Start Time</TableData> */}
                <TableData sx={{ fontWeight: 600 }}>Status</TableData>
                <TableData sx={{ fontWeight: 600 }}>Start Meeting</TableData>
                <TableData sx={{ fontWeight: 600 }}>Action</TableData>
              </TableRow>
            </TableHead>{" "}
            <TableBody>
              {filterMeeting
                ? filterMeeting
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((listData, i) => (
                      <TableRow>
                        <Td>{i + 1}</Td>

                        <Td>
                          {dayjs(listData.startDate).format("DD-MM-YYYY")},
                          Time: {listData.startTime}
                        </Td>
                        <Td>{listData.meetingType}</Td>
                        <Td>
                          <Box
                            sx={{
                              py: 1,
                              objectFit: "contain",
                              borderRadius: "5px",
                              background:
                                getStatus(
                                  listData.startDate,
                                  listData.startTime,
                                  listData.expiryDate,
                                  listData.expiryTime
                                ) === "Expired"
                                  ? "#85848430"
                                  : "#8281e74d",
                            }}
                          >
                            {getStatus(
                              listData.startDate,
                              listData.startTime,
                              listData.expiryDate,
                              listData.expiryTime
                            )}
                          </Box>
                        </Td>
                        <Td>
                          <Button
                            disabled={
                              getStatus(
                                listData.startDate,
                                listData.startTime,
                                listData.expiryDate,
                                listData.expiryTime
                              ) === "Expired"
                            }
                            onClick={() => handleJoinClick(listData)}
                            variant="contained"
                            size="small"
                          >
                            Join
                          </Button>
                        </Td>

                        <Td sx={{ textAlign: "center" }}>
                          {/* <Tooltip title="Copy Meeting link">
                      <IconButton
                        value={listData.link}
                        onClick={() => handleCopyLink(listData.link)}
                      >
                        <ContentCopyIcon fontSize="small" />
                      </IconButton>
                    </Tooltip> */}
                          <Tooltip title="Edit">
                            <IconButton
                              onClick={() =>
                                handleupdateModelOpen(listData._id)
                              }
                            >
                              <EditIcon color="blue" fontSize="small" />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Delete">
                            <IconButton
                              onClick={() =>
                                setDeleteMeetingItem({
                                  itemToDelete: listData._id,
                                  open: true,
                                })
                              }
                            >
                              <DeleteIcon fontSize="small" color="error" />
                            </IconButton>
                          </Tooltip>
                        </Td>
                      </TableRow>
                    ))
                : allMeetingList
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((listData, i) => (
                      <TableRow>
                        <Td>{i + 1}</Td>

                        <Td>
                          {dayjs(listData.startDate).format("DD-MM-YYYY")},
                          Time: {listData.startTime}
                        </Td>
                        <Td>{listData.meetingType}</Td>
                        <Td>
                          <Box
                            sx={{
                              py: 1,
                              objectFit: "contain",
                              borderRadius: "5px",
                              background:
                                getStatus(
                                  listData.startDate,
                                  listData.startTime,
                                  listData.expiryDate,
                                  listData.expiryTime
                                ) === "Expired"
                                  ? "#85848430"
                                  : "#8281e74d",
                            }}
                          >
                            {getStatus(
                              listData.startDate,
                              listData.startTime,
                              listData.expiryDate,
                              listData.expiryTime
                            )}
                          </Box>
                        </Td>
                        <Td>
                          <Button
                            disabled={
                              getStatus(
                                listData.startDate,
                                listData.startTime,
                                listData.expiryDate,
                                listData.expiryTime
                              ) === "Expired"
                            }
                            onClick={() => handleJoinClick(listData)}
                            variant="contained"
                            size="small"
                          >
                            Join
                          </Button>
                        </Td>

                        <Td sx={{ textAlign: "center" }}>
                          {/* <Tooltip title="Copy Meeting link">
                      <IconButton
                        value={listData.link}
                        onClick={() => handleCopyLink(listData.link)}
                      >
                        <ContentCopyIcon fontSize="small" />
                      </IconButton>
                    </Tooltip> */}
                          <Tooltip title="Edit">
                            <IconButton
                              onClick={() =>
                                handleupdateModelOpen(listData._id)
                              }
                            >
                              <EditIcon color="blue" fontSize="small" />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Delete">
                            <IconButton
                              onClick={() =>
                                setDeleteMeetingItem({
                                  itemToDelete: listData._id,
                                  open: true,
                                })
                              }
                            >
                              <DeleteIcon fontSize="small" color="error" />
                            </IconButton>
                          </Tooltip>
                        </Td>
                      </TableRow>
                    ))}
            </TableBody>
          </Table>
          {!allMeetingList.length === 0 && (
            <Box align="center" colSpan={12} py={2}>
              <Typography variant="h6" align="center">
                Live sessions Not found!
              </Typography>
            </Box>
          )}
          {!filterMeeting.length && (
            <Box align="center" colSpan={12} py={2}>
              <Typography variant="h6" align="center">
                Live sessions Not found!
              </Typography>
            </Box>
          )}
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={allMeetingList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </Box>

      {/* ============= Add/Update model =============== */}
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <Box sx={style} component="form" onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                sx={{
                  textAlign: "center",
                  fontWeight: "bold",
                  fontSize: "20px",
                }}
                component="h2"
              >
                {editId ? "Update Meeting" : "Create Meeting"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Label id="demo-simple-select-label">Meeting Type</Label>
              <Select
                required
                size="small"
                fullWidth
                name="meetingType"
                type="text"
                value={liveData.meetingType || "LiveStreaming"}
                onChange={handleChange}
              >
                <MenuItem value="OneONoneCall">One-On-One Call </MenuItem>
                <MenuItem value="GroupCall">Group Call </MenuItem>
                <MenuItem value="LiveStreaming">Live Stream </MenuItem>
              </Select>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Label id="demo-simple-select-label">Participant Type</Label>

              <Select
                required
                size="small"
                fullWidth
                name="participantType"
                type="text"
                value={
                  liveData.participantType || "Who are enrolled in this course"
                }
                onChange={handleChange}
                disabled={
                  liveData.meetingType === "OneONoneCall" ? true : false
                }
              >
                <MenuItem value="Single">Single User</MenuItem>
                <MenuItem value="Who are enrolled in this course">
                  Who are enrolled in this course
                </MenuItem>
                <MenuItem value="Selected">Selected Users</MenuItem>
              </Select>
            </Grid>
            {(liveData.participantType === "Single" ||
              liveData.participantType === "Selected") && (
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Label id="demo-simple-select-label">Subscribes User</Label>
                <Select
                  multiple={
                    liveData.participantType === "Single" ? false : true
                  }
                  size="small"
                  fullWidth
                  name="participants"
                  type="text"
                  value={liveData.participants || ""}
                  onChange={handleChange}
                >
                  {userList.map((user, i) => (
                    <MenuItem key={user._id} value={user._id}>
                      {user.name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            )}

            {liveData.participantType === "Who are enrolled in this course" && (
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Label id="demo-simple-select-label">Select Course</Label>
                <Select
                  size="small"
                  fullWidth
                  name="course"
                  type="text"
                  placeholder="Select Course"
                  options={courseList}
                  value={liveData.course || ""}
                  onChange={handleChangeCourseList}
                >
                  {courseList.map((c) => (
                    <MenuItem value={c._id} key={c._id}>
                      {c.title}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            )}

            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Label id="demo-simple-select-label">Start Date</Label>
              <TextField
                required
                type="date"
                size="small"
                fullWidth
                name="courseDate"
                value={liveData.courseDate}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Label id="demo-simple-select-label">Start Time</Label>
              <TextField
                required
                type="time"
                size="small"
                fullWidth
                name="startTime"
                value={liveData.startTime}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Label id="demo-simple-select-label">Expiry Date</Label>
              <TextField
                required
                type="date"
                size="small"
                fullWidth
                name="expiryDate"
                value={liveData.expiryDate}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Label id="demo-simple-select-label">Expiry Time</Label>
              <TextField
                required
                type="time"
                size="small"
                fullWidth
                name="expiryTime"
                value={liveData.expiryTime}
                onChange={handleChange}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              display="flex"
              justifyContent={{
                xs: "center",
                sm: "center",
                md: "flex-end",
                lg: "flex-end",
              }}
            >
              <Button type="submit" variant="contained">
                {editId ? "Update" : "Create"}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Dialog>
      {/* ======DELETE Model */}
      <Dialog
        open={deleteMeetingItem.open}
        onClose={handleCloseDeleteModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description" fontWeight={600}>
            Are you sure you want to delete this meeting?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteModal}>No</Button>
          <Button onClick={(e) => handleDeleteMeeting(e)} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Live;
