import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { post, get } from "../api/apiMethods";
import { useFormik } from "formik";
import * as Yup from "yup";
import { LoadingButton } from "@mui/lab";
import Laptop from "../assets/Images/laptop.png";
import towPiece from "../assets/Images/towpiece.png";
import { urls } from "../api/urlConstants";
import FormInput from "./FormInput";
import NavbarContext from "../context/NavbarContext";
import { RippelVideoButton } from "./RippelVideoButton";

const ResetPasswordLogin = () => {
  const navigate = useNavigate();
  const [header, setHeader] = useState("");
  const [user, setUser] = useState({});
  const { access_token } = useParams();
  const [loading, setLoading] = useState(false);
  const { setShowNavbar } = useContext(NavbarContext);

  useEffect(() => {
    setShowNavbar(false);

    return () => setShowNavbar(true);
  }, []);

  const style = {
    height: "100vh",
    width: "100%",
    display: "flex",
  };

  const leftPart = {
    display: "flex",
    width: { xs: "100%", sm: "100%", md: "50%", lg: "40%" },
    height: "100%",
    backgroundImage: `url(${towPiece})`,
    backgroundRepeat: "no-repeat",
    backgroundPositionY: "100%",
  };

  const inputForm = {
    width: { xs: "100%", sm: "100%", md: "80%", lg: "60%" },
    margin: "150px auto",
    // padding: "0 20px",
  };

  const rightPart = {
    position: "relative",
    width: { xs: "100%", sm: "65%", md: "65%", lg: "60%" },
    display: { xs: "none", sm: "none", md: "block", lg: "block" },
    backgroundImage: `url(${Laptop})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    // backgroundPosition: "center",
    backgroundPositionX: { md: "center", lg: "35px" },
    backgroundPositionY: "center",
    justifyContent: "end",
  };

  const FormStyle = {
    width: "300px",
    // margin: { xs: "100px 10px", sm: "100px 10px", md: "100px 10px" },
    // padding: "100px ",
  };

  const formik = useFormik({
    initialValues: {
      newpassword: "",
      conpassword: "",
    },

    validationSchema: Yup.object({
      newpassword: Yup.string().required().label("Password").max(32),
      conpassword: Yup.string().required().label("Password").max(32),
    }),
    onSubmit: async (values) => {
      // console.log(values, "vvvvvvvvvv==========");
      try {
        const body = {
          newPassword: values.newpassword,
          confirmPassword: values.conpassword,
          token: access_token,
        };

        const { data, status } = await post(
          urls.user.resetForgotPasswordInLink,
          body
        );
        navigate("/");
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    },
    validateOnBlur: false,
  });
  return (
    <>
      <Box sx={style} p={0}>
        <Box sx={leftPart}>
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2} sx={inputForm}>
              <Grid item xs={12} md={12}>
                <Typography variant="h5" fontWeight={600} my={2}>
                  Change Password
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <FormInput
                  formik={formik}
                  name="newpassword"
                  type="password"
                  label="New Password"
                  size="small"
                />
                <FormInput
                  formik={formik}
                  name="conpassword"
                  type="password"
                  label="Confirm Password"
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}></Grid>

              <Grid item xs={12} sm={12} md={12} lg={12}>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  loading={loading}
                  fullWidth
                >
                  Submit
                </LoadingButton>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Link to="/" style={{ width: "100%" }}>
                  <Button type="submit" variant="outlined" fullWidth>
                    Back to Login
                  </Button>
                </Link>
              </Grid>
            </Grid>
          </form>
        </Box>
        <Box sx={rightPart}>
          {/* <img src={Laptop} width="100%" height="100%" alt="loading..." /> */}
          <Box
            sx={{
              position: "absolute",
              margin: "auto",
              top: { md: "40%", lg: "40%" },
              left: { md: "26%", lg: "33%" },
              transform: "rotate(-20deg)",
            }}
          >
            <RippelVideoButton />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ResetPasswordLogin;
