import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  OutlinedInput,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  InputAdornment,
  IconButton,
  TableContainer,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import styled from "@emotion/styled";

import { Link, useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import ClearIcon from "@mui/icons-material/Clear";
// custom imports
import AddCourse from "./AddCourse";

// Icons
import EditIcon from "@mui/icons-material/Edit";
import { Delete } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import { Search } from "@mui/icons-material";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import FilterListIcon from "@mui/icons-material/FilterList";

// apis
import { post, get, del, put } from "../api/apiMethods";
import { urls } from "../api/urlConstants";
import { async } from "q";

const Label = styled("label")(() => ({
  fontWeight: "bold",
  paddingLeft: "10px",
  fontSize: "14px",
}));

const Heading = styled(TableCell)(() => ({
  fontWeight: "bold",
  textAlign: "center",
}));
const Data = styled(TableCell)(() => ({
  textAlign: "center",
}));

const Products = () => {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const navigate = useNavigate();
  const [file, setFile] = useState("");
  const [material, setMaterial] = useState(null);
  const [search, setSearch] = useState("");
  const [searchFilter, setSearchFilter] = useState([]);
  const [courseList, setCourseList] = useState([]);
  const [editCourse, setEditCourse] = useState(null);
  const [courseId, setCourseId] = useState("");
  const [menuList, setMenuList] = useState([]);
  const [selectedMenu, setSelectedMenu] = useState("");
  const [materialURL, setMaterialURL] = useState();
  const [filteredCourses, setFilteredCourses] = useState([]);
  const [deleteModal, setDeleteModal] = React.useState({
    open: false,
    itemToDelete: "",
  });

  useEffect(() => {
    setSelectedMenu("All");
  }, []);

  const [editFormData, setEditFormData] = useState({
    title: "",
    menu: "",
    pdu: "",
    courseHours: "",

    description: "",
    thumbnailImage: "",
    overview: [],
    selfPacedInfo: {
      earlyBirdEndDate: "",
      earlyBirdPrice: "",
      standardPrice: "",
    },

    benefits: [],

    isPaid: false,
    isTrending: false,
  });

  const handleNavigateCourse = (id) => {
    const selectedCourse = filteredCourses.find((course) => course._id === id);
    if (selectedCourse) {
      const selectedCourseTitle = selectedCourse.title;
      navigate("/upload-courses/adding", {
        state: {
          selectedCourseIds: id,
          selectedCourseTitle: selectedCourseTitle,
        },
      });
    }
  };

  const handleSearch = (e) => {
    const { value } = e.target;
    setSelectedMenu("All");
    setSearch(value.trim());
    if (value.trim() !== "") {
      filteredCourses.length > 0 &&
        setSearchFilter(
          filteredCourses.filter((ele) =>
            ele.title.toLowerCase().includes(value.toLowerCase().trim())
          )
        );
    } else {
      setSearchFilter([]);
    }
  };

  const getCourse = async () => {
    try {
      const data = await get(urls.course.getList);
      setCourseList(data.data.result.data);
      setFilteredCourses(data.data.result.data);
      const getMenuList = data.data.result.data.map((c) => c.menu.name);
      const uniqueMenuList = [...new Set(getMenuList)];
      setMenuList(["All", ...uniqueMenuList]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCourse();
  }, [editCourse]);

  const handleChange = (event) => {
    setSelectedMenu(event.target.value);
  };

  useEffect(() => {
    if (selectedMenu) {
      if (selectedMenu === "All") {
        setFilteredCourses(courseList);
      } else {
        setFilteredCourses(
          courseList.filter((c) => c.menu.name === selectedMenu)
        );
      }
    }
  }, [selectedMenu]);

  const handleCourseEdit = (id, data) => {
    // console.log(data, "dataaaaaaaaaaaaa");
    setEditCourse(id);
    setEditFormData({
      title: data.title,
      menu: data.menu,
      pdu: data.pdu,
      isPaid: data.isPaid,
      isTrending: data.isTrending,
      courseHours: data.courseHours,

      description: data.description,
      thumbnailImage: data.thumbnailImage,
      overview: data.courseDetails.overview || [],

      benefits: data.courseDetails.benefits || [],
      selfPacedInfo: {
        earlyBirdEndDate: data.selfPacedInfo.earlyBirdEndDate,
        earlyBirdPrice: data.selfPacedInfo.earlyBirdPrice,
        standardPrice: data.selfPacedInfo.standardPrice,
      },

      courseId: editCourse,
    });
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const handleClickOpen = (id, material) => {
    // console.log(material, "material");
    setOpen(true);
    setCourseId(id);
    if (material) {
      const materialURLs = new URL(material);
      const fileName = decodeURIComponent(
        materialURLs.pathname.split("/").pop()
      );
      setMaterial(fileName);
    }
    setMaterialURL(material);
  };

  const handleClose = () => {
    setOpen(false);
    setMaterial(null);
  };
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
  };
  const handleUpload = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("file", file);
      const res = await post(
        `${urls.course.uploadCourseMaterial}/${courseId}`,
        formData
      );
      getCourse();
      handleClose();
    } catch (error) {
      console.error(error);
    }
  };
  // console.log(materialURL, "urlss");

  const handleDownload = () => {
    try {
      window.open(materialURL, "_blank");
    } catch (error) {
      console.error(error);
    }
  };
  const handleDeleteMaterial = async (e) => {
    e.preventDefault();
    try {
      const res = await put(`${urls.course.deleteCourseMaterial}/${courseId}`);
      getCourse();
      setMaterial(null);
    } catch (error) {
      console.error(error);
    }
  };
  const handleCloseDeleteModal = () => {
    setDeleteModal({ open: false, itemToDelete: "" });
  };

  const handleDeleteone = async (id) => {
    try {
      const response = await del(
        `${urls.course.deleteCourse}/${deleteModal.itemToDelete}`
      );

      setDeleteModal({ open: false, itemToDelete: "" });
      getCourse();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Box sx={{ padding: "10px 30px" }}>
      {editCourse !== null ? (
        <AddCourse
          edit={editCourse}
          formData={editFormData}
          setEditCourse={setEditCourse}
          courses={filteredCourses}
        />
      ) : (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: 2,
              py: 2,
            }}
          >
            <Grid container>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box my={1}>
                  <FormControl sx={{ width: 227, marginRight: "10px" }}>
                    <Label id="demo-simple-select-label">
                      <FilterListIcon fontSize="small" /> Filter Category
                    </Label>
                    <Select
                      labelId="demo-multiple-name-label"
                      id="demo-multiple-name"
                      displayEmpty
                      input={
                        <OutlinedInput
                          sx={{
                            borderRadius: "20px",
                            textAlign: "center",
                            height: "35px",
                          }}
                        />
                      }
                      value={selectedMenu}
                      onChange={handleChange}
                      size="small"
                    >
                      {menuList?.map((menuList) => (
                        <MenuItem key={menuList} value={menuList}>
                          {menuList}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl sx={{ width: 227 }}>
                    <Label id="demo-simple-select-label">
                      Search courses...
                    </Label>

                    <TextField
                      placeholder="Search"
                      size="small"
                      value={search}
                      onChange={handleSearch}
                      variant="outlined"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton edge="end" type="submit">
                              <Search />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      sx={{
                        "& .MuiInputBase-root": {
                          borderRadius: "20px",
                          height: "35px",
                          minWidth: "227px",
                          boxShadow: 0.5,
                        },
                      }}
                    />
                  </FormControl>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    flexDirection: {
                      xs: "column",
                      sm: "row",
                      md: "row",
                    },
                  }}
                >
                  <Link to="/addCourse">
                    <Tooltip title="Add course">
                      <Button
                        size="small"
                        variant="contained"
                        startIcon={<AddIcon />}
                        sx={{ px: 2, py: 1 }}
                      >
                        Course
                      </Button>
                    </Tooltip>
                  </Link>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <Heading>S.No</Heading>
                  <Heading>Category</Heading>
                  <Heading>Course Name</Heading>
                  <Heading>PDU</Heading>
                  <Heading>Duration</Heading>
                  <Heading>Content</Heading>
                  <Heading>Material</Heading>
                  <Heading>Status</Heading>
                  <Heading>Action</Heading>
                </TableRow>
              </TableHead>
              <TableBody>
                {search
                  ? searchFilter.map((course, index) => (
                      <TableRow key={course._id}>
                        <Data>{index + 1}</Data>
                        <Data>{course.menu.name}</Data>
                        <Data>{course.title}</Data>
                        <Data>{course.pdu}</Data>
                        <Data>{course.courseHours}</Data>
                        <Data>
                          <Tooltip title="Upload course">
                            <Button
                              onClick={() => handleNavigateCourse(course._id)}
                            >
                              Upload
                            </Button>
                          </Tooltip>
                        </Data>

                        <Data>
                          <Tooltip title="Upload Material">
                            <Button
                              onClick={() =>
                                handleClickOpen(course._id, course.material)
                              }
                            >
                              <FileUploadIcon />
                            </Button>
                          </Tooltip>
                        </Data>

                        <Data>
                          <Button
                            size="small"
                            variant="text"
                            color={
                              course.isPaid != true ? "primary" : "success"
                            }
                          >
                            {course.isPaid != true ? "Free" : "Paid"}
                          </Button>
                        </Data>

                        <Data>
                          <Tooltip title="Edit course">
                            <Button
                              onClick={() =>
                                handleCourseEdit(course._id, course)
                              }
                            >
                              <EditIcon />
                            </Button>
                          </Tooltip>
                          <Tooltip title="Delete">
                            <Button
                              onClick={() =>
                                setDeleteModal({
                                  itemToDelete: course._id,
                                  open: true,
                                })
                              }
                            >
                              <Delete color="error" />
                            </Button>
                          </Tooltip>
                        </Data>
                      </TableRow>
                    ))
                  : filteredCourses.map((course, index) => (
                      <TableRow key={course._id}>
                        <Data>{index + 1}</Data>
                        <Data>{course.menu.name}</Data>
                        <Data>{course.title}</Data>
                        <Data>{course.pdu}</Data>
                        <Data>{course.courseHours}</Data>

                        <Data>
                          <Tooltip title="Upload course">
                            <Button
                              onClick={() => handleNavigateCourse(course._id)}
                            >
                              Upload
                            </Button>
                          </Tooltip>
                        </Data>

                        <Data>
                          <Tooltip title="Upload Material">
                            <Button
                              onClick={() =>
                                handleClickOpen(course._id, course.material)
                              }
                            >
                              <FileUploadIcon />
                            </Button>
                          </Tooltip>
                        </Data>

                        <Data>
                          <Button
                            size="small"
                            variant="text"
                            color={
                              course.isPaid != true ? "primary" : "success"
                            }
                          >
                            {course.isPaid != true ? "Free" : "Paid"}
                          </Button>
                        </Data>

                        <Data>
                          <Tooltip title="Edit course">
                            <Button
                              onClick={() =>
                                handleCourseEdit(course._id, course)
                              }
                            >
                              <EditIcon />
                            </Button>
                          </Tooltip>
                          <Tooltip title="Delete">
                            <Button
                              onClick={() =>
                                setDeleteModal({
                                  itemToDelete: course._id,
                                  open: true,
                                })
                              }
                            >
                              <Delete color="error" />
                            </Button>
                          </Tooltip>
                        </Data>
                      </TableRow>
                    ))}

                <TableRow>
                  {!filteredCourses.length && !search.trim() && (
                    <Data align="center" colSpan={12}>
                      <Typography variant="h6" align="center">
                        No data found
                      </Typography>
                    </Data>
                  )}
                  {search.trim() && !searchFilter.length && (
                    <Data align="center" colSpan={12}>
                      <Typography variant="h6" align="center">
                        No data found
                      </Typography>
                    </Data>
                  )}
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}

      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle
          id="responsive-dialog-title"
          sx={{ fontSize: "20px", fontWeight: "bold" }}
        >
          {"Upload Your Material"} {filteredCourses.material}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <input type="file" onChange={handleFileChange} />
          </DialogContentText>
        </DialogContent>
        <DialogContent>
          <div>{material}</div>
          {material ? (
            <Button onClick={handleDeleteMaterial}>
              <ClearIcon />
            </Button>
          ) : (
            ""
          )}
        </DialogContent>
        <DialogActions>
          {material ? (
            <Button size="small" variant="outlined" onClick={handleDownload}>
              download
            </Button>
          ) : (
            ""
          )}
          <Button variant="contained" onClick={handleUpload} autoFocus>
            Upload
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={deleteModal.open}
        onClose={handleCloseDeleteModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description" fontWeight={600}>
            Are you sure you want to delete this?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteModal}>No</Button>
          <Button onClick={(e) => handleDeleteone(e)} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Products;
