import React from "react";
import { Box, Typography, styled, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Container = styled(Box)(() => ({
  alignItems: "center",
  display: "flex",
  flex: 1,
  justifyContent: "center",
  flexDirection: "column",
  position: "absolute",
  height: "80vh",
  width: "80vw",
}));

function PageNotFound() {
  const navigate = useNavigate();
  const handleNavigateToHome = () => {
    navigate(-1);
  };
  return (
    <>
      <Container>
        <Typography variant="h6" sx={{ fontSize: "4rem", textAlign: "center" }}>
          Error 404{" "}
        </Typography>

        <Typography>Page not found!</Typography>
        <Button
          size="small"
          onClick={handleNavigateToHome}
          sx={{
            margin: "10px 0",
          }}
        >
          Go Back
        </Button>
      </Container>
    </>
  );
}

export default PageNotFound;
