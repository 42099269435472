import * as React from "react";
import { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Tooltip from "@mui/material/Tooltip";
import Avatar from "@mui/material/Avatar";
import { Link, useLocation } from "react-router-dom";
import ListItem from "@mui/material/ListItem";
import Drawer from "@mui/material/Drawer";
import { settings } from "./AdminSettings";
import { MenuList } from "@mui/material";

import { theme } from "../../../theme";

// Icons
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import { Stream } from "@mui/icons-material";
import CategoryIcon from "@mui/icons-material/Category";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import MoreIcon from "@mui/icons-material/MoreVert";
import ReceiptIcon from "@mui/icons-material/Receipt";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";
import UploadFileIcon from "@mui/icons-material/UploadFile";

// assets
import image1 from "../../../assets/wesprucelogo icon.png";

// context
import UserContext from "../../../context/UserContext";

export const listdata = [
  {
    id: "1",
    path: "/menu",
    name: "Category",
    icon: <CategoryIcon fontSize="small" />,
  },
  {
    id: "2",
    path: "/products",
    name: "Courses",
    icon: <MenuBookIcon fontSize="small" />,
  },

  // {
  //   id: "3",
  //   path: "/dates",
  //   name: "Pricing",
  // },
  {
    id: "4",
    path: "/upload-courses/adding",
    name: "Upload Course",
    icon: <UploadFileIcon fontSize="small" />,
  },
  {
    id: "5",
    path: "/manage-courses",
    name: "Manage Course",
    icon: <ManageAccountsIcon fontSize="small" />,
  },
  {
    id: "6",
    path: "/subscribers-info",
    name: "Subscribers",
    icon: <SubscriptionsIcon fontSize="small" />,
  },
  {
    id: "7",
    path: "/invoice",
    name: "Invoice",
    icon: <ReceiptIcon fontSize="small" />,
  },
  // {
  //   id: 9,
  //   path: "/enquirers",
  //   name: "Enquiries",
  // },
  {
    id: 10,
    path: "/coupon",
    name: "Coupon",
    icon: <CardGiftcardIcon fontSize="small" />,
  },
  {
    id: 11,
    path: "/live",
    name: "Live",
    icon: <Stream fontSize="small" />,
  },
];

export default function Navbar() {
  const { user, setUser } = React.useContext(UserContext);
  const [selectedIndex, setSelectedIndex] = useState(-1);

  const location = useLocation();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [show, setShow] = useState(false);

  React.useEffect(() => {
    let user = window.localStorage.getItem("user");
    if (user) {
      setUser(JSON.parse(user));
    }
  }, []);

  const VALID_PATH_NAMES = [
    "/",
    "/menu",
    "/products",
    "/addCourse",
    "/upload-courses/adding",
    "/manage-courses",
    "/subscribers-info",
    "/addcoupon",
    "/invoice",
    "/coupon",
    "/account-setting",
    "/change-password",
    "/forgot-password",
    "/resetPassword/",
    "/live",
    "/room/:roomID",
  ];

  React.useEffect(() => {
    if (!window.localStorage.getItem("user")) {
      setShow(false);
    } else {
      setShow(true);
    }
  }, [location.pathname]);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };
  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = () => {
    window.localStorage.removeItem("access_token");
    window.localStorage.removeItem("user");
    window.location.href = "/";
  };

  const mobileMenuId = "primary-search-account-menu-mobile";

  const renderMobileMenu = (
    <>
      <Menu
        sx={{ mt: "54px", right: "0px" }}
        anchorEl={mobileMoreAnchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        id={mobileMenuId}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={isMobileMenuOpen}
        onClose={handleMobileMenuClose}
      >
        <Box sx={{ p: 2 }}>
          <Typography
            variant="h6"
            component="div"
            fontWeight={600}
            fontSize="16px"
            sx={{ color: `${theme.palette.primary.dark}` }}
          >
            Welcome To Your LMS
          </Typography>

          {listdata.map((elem, index) => (
            <MenuList key={index}>
              <Link
                style={{
                  textDecoration: "none",
                  fontWeight: "bold",
                  fontSize: "12px",
                  color: "black",
                }}
                to={elem.path}
              >
                <ListItem
                  style={{
                    backgroundColor: location.pathname.includes(elem.path)
                      ? `${theme.palette.primary.main}`
                      : "inherit",

                    color: location.pathname.includes(elem.path)
                      ? "white"
                      : "inherit",
                    borderRadius: "10px",
                  }}
                  onClick={() => {
                    setSelectedIndex(index);
                  }}
                >
                  {elem.icon && (
                    <span style={{ marginRight: "8px" }}>{elem.icon}</span>
                  )}
                  {elem.name}
                </ListItem>
              </Link>
            </MenuList>
          ))}
        </Box>
      </Menu>
    </>
  );
  if (!show) return;
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        sx={{ zIndex: 10, position: "fixed", backgroundColor: "#fafafa" }}
      >
        <Toolbar sx={{ paddingLeft: "0px", paddingRight: "0px" }}>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            sx={{
              mr: 2,
              "&:hover": {
                backgroundColor: "white",
              },
            }}
          >
            <Box sx={{ width: 100 }}>
              <img height={50} src={image1} alt="img" />
            </Box>
          </IconButton>
          <Box pl={4} />
          <Typography
            sx={{
              color: `${theme?.palette?.primary?.dark}`,
              fontWeight: 700,
              display: { xs: "none", sm: "none", md: "flex" },
              ml: "10px",
            }}
          >
            Welcome To Your Learning Management System
          </Typography>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "flex", md: "none" },
            }}
          >
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {listdata.map((elem, index) => (
                <MenuList key={index}>
                  <Link
                    style={{
                      textDecoration: "none",
                      fontWeight: "bold",
                      color: "black",
                    }}
                    to={elem.path}
                  >
                    <ListItem
                      style={{
                        backgroundColor: location.pathname.includes(elem.path)
                          ? "#757de8"
                          : "inherit",
                        color: location.pathname.includes(elem.path)
                          ? "white"
                          : "inherit",
                        borderRadius: "10px",
                      }}
                      onClick={() => {
                        setSelectedIndex(index);
                      }}
                    >
                      {elem.name}
                    </ListItem>
                  </Link>
                </MenuList>
              ))}
            </Menu>
          </Box>

          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: "flex" }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar
                  sx={{
                    borderRadius: "20px",
                    fontSize: "2.rem",
                    backgroundColor: `${theme?.palette?.secondary?.main}`,
                    border: "1px solid lightgray",
                    width: "40px",
                    height: "40px",
                    objectFit: "contain",
                    overflow: "hidden",
                  }}
                  alt="Remy Sharp"
                  src={user?.image}
                >
                  {user.name?.charAt(0).toUpperCase()}
                </Avatar>
              </IconButton>
            </Tooltip>
            <Typography
              noWrap
              sx={{
                mr: 2,
                mt: 1,
                ml: 2,
                color: `${theme?.palette?.primary?.dark}`,
                textDecoration: "none",
                display: { xs: "none", md: "flex" },
                fontWeight: "bold",
                fontSize: "16px",
              }}
            >
              {user?.name
                ? user.name.charAt(0).toUpperCase() +
                  user.name.slice(1).toLowerCase()
                : ""}
            </Typography>

            <Menu
              sx={{ mt: "54px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings
                .filter((s) => s.path !== "/logout")
                .map((setting, index) => (
                  <MenuList key={index}>
                    <Link
                      style={{
                        textDecoration: "none",
                        fontWeight: "bold",
                        color: "black",
                      }}
                      to={setting.path}
                    >
                      <MenuItem onClick={handleCloseUserMenu}>
                        <Typography textAlign="center">
                          {setting.name}
                        </Typography>
                      </MenuItem>
                    </Link>
                  </MenuList>
                ))}
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
          </Box>
          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="black"
              sx={{ padding: 0 }}
            >
              <MoreIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      <Drawer
        sx={{
          width: "drawerWidth",
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: "drawerWidth",
            boxSizing: "border-box",
          },
          display: { xs: "none", md: "flex" },
        }}
        variant="permanent"
        anchor="left"
      >
        <Toolbar
          sx={{
            backgroundColor: "white",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            sx={{
              "&:hover": {
                backgroundColor: "white",
              },
            }}
          >
            <img height={50} width={50} src={image1} alt="" />
          </IconButton>
        </Toolbar>
        <Box p={1}>
          {listdata.map((elem, index) => (
            <MenuList key={index}>
              <Link
                style={{
                  textDecoration: "none",
                  fontWeight: "bold",
                  fontSize: "14px",
                  color: "black",
                }}
                to={elem.path}
              >
                <ListItem
                  style={{
                    backgroundColor: location.pathname.includes(elem.path)
                      ? `${theme.palette.primary.main}`
                      : "inherit",

                    color: location.pathname.includes(elem.path)
                      ? "white"
                      : "inherit",
                    borderRadius: "10px",
                  }}
                  onClick={() => {
                    setSelectedIndex(index);
                  }}
                >
                  {elem.icon && (
                    <span style={{ marginRight: "8px" }}>{elem.icon}</span>
                  )}
                  {elem.name}
                </ListItem>
              </Link>
            </MenuList>
          ))}
        </Box>
      </Drawer>
    </Box>
  );
}
