import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Button,
  Typography,
  styled,
  Stack,
  TextField,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import dayjs from "dayjs";
import { theme } from "../theme";

// custom imports
import FormInput from "./FormInput";
import FormSelect from "./FormSelect";

// Icons
import AddIcon from "@mui/icons-material/Add";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteIcon from "@mui/icons-material/Delete";

// forms
import { useFormik } from "formik";
import * as Yup from "yup";

// api
import { post, put, get } from "../api/apiMethods";
import { urls } from "../api/urlConstants";

const Label = styled("label")(() => ({
  fontWeight: "bold",
  fontSize: "14px",
  paddingLeft: "10px",
}));

const Title = styled(Typography)(() => ({
  fontWeight: "bold",
  fontSize: "20px",
  textAlign: "left",
  marginLeft: "10px",
}));

const InputLabel = styled(Typography)(() => ({
  fontWeight: "bold",
  color: "#747DE8",
  fontSize: "20px",
}));

export default function AddCourse({ edit, formData, setEditCourse, history }) {
  const fileInputRef = React.useRef(null);
  const [imageFile, setImageFile] = React.useState(
    formData && formData.thumbnailImage ? formData.thumbnailImage : null
  );
  const [selectedImage, setSelectedImage] = useState(null);
  const [inputlist, setInputList] = useState(
    formData?.overview || [{ point: "" }]
  );
  const [inputlistBenifits, setInputListBenifits] = useState(
    formData?.benefits || [{ point: "" }]
  );

  useEffect(() => {
    if (formData && Object.keys(formData).length) {
      setImageFile(formData.thumbnailImage);
    }
  }, [formData]);

  const [list, setList] = useState([]);
  const [menuList, setMenuList] = useState([]);

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const handleUploadButtonClick = () => {
    fileInputRef?.current?.click();
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedImage(file);
    setImageFile(URL.createObjectURL(file));
    formik.setFieldError("thumbnailImage", "");
  };

  const formik = useFormik({
    initialValues: {
      title: edit ? formData.title : "",
      menu: edit ? formData.menu.name : "",
      isPaid: edit ? formData.isPaid : false,
      isTrending: edit ? formData.isTrending : false,
      pdu: edit ? formData.pdu : "",
      description: edit ? formData.description : "",
      earlyBirdEndDate: edit
        ? dayjs(formData?.selfPacedInfo.earlyBirdEndDate || Date.now()).format(
            "YYYY-MM-DD"
          )
        : dayjs(Date.now()).format("YYYY-MM-DD"),
      earlyBirdPrice: edit ? formData?.selfPacedInfo.earlyBirdPrice : "",
      standardPrice: edit ? formData?.selfPacedInfo.standardPrice : "",
    },
    validationSchema: Yup.object({
      description: Yup.string().required().label("Description"),
      isPaid: Yup.boolean().required().label("Is paid course"),
      isTrending: Yup.boolean().label("Is a Trending Course"),
      menu: Yup.string().required().label("Category Name"),
      title: Yup.string().required().label("Title").max(50),
      pdu: Yup.number().nullable().label("Pdu"),
      earlyBirdEndDate: Yup.string().when("isPaid", {
        is: (isPaid) => isPaid === true,
        then: () => Yup.string().required().label("Early Bird EndDate"),
        otherwise: () => Yup.string().label("Early Bird EndDate"),
      }),
      earlyBirdPrice: Yup.number().when("isPaid", {
        is: (isPaid) => isPaid === true,
        then: () => Yup.number().required().label("Early Bird Price"),
        otherwise: () => Yup.number().nullable().label("Early Bird Price"),
      }),
      standardPrice: Yup.number().when("isPaid", {
        is: (isPaid) => isPaid === true,
        then: () => Yup.number().required().label("Standard Price"),
        otherwise: () => Yup.number().nullable().label("Standard Price"),
      }),
    }),
    enableReinitialize: true,
    onSubmit: async (values) => {
      if (!imageFile) {
        return toast.success("Please select a thumnail image");
      }

      setLoading(true);
      try {
        let menuId;
        if (edit) {
          menuId = menuList.filter((m) => m?.name === values?.menu)[0]?._id;
        } else {
          menuId = menuList.filter((m) => m?.name === values?.menu)[0]?._id;
        }
        const body = {
          description: values.description,
          title: values.title,
          pdu: values.pdu,
          menu: menuId,
          isPaid: values.isPaid,
          isTrending: values.isTrending,
          courseDetails: {
            overview: inputlist,
            benefits: inputlistBenifits,
          },
          selfPacedInfo: {
            standardPrice: values.standardPrice,
            earlyBirdPrice: values.earlyBirdPrice,
            earlyBirdEndDate: new Date(values.earlyBirdEndDate),
          },
        };
        const formsData = new FormData();
        console.log(body, "body");
        formsData.append("bodyData", JSON.stringify(body));

        formsData.append("file", selectedImage);
        if (!edit) {
          const { data } = await post(
            `${urls.course.createCourse}/${menuId}`,
            formsData
          );
          console.log(data);
          navigate(history ? history : "/products");
        } else {
          const { data } = await put(
            `${urls.course.editCourse}/${edit}`,
            formsData
          );
          console.log(data, "data");
          navigate(history ? history : "/products");
        }
        setEditCourse(null);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    },
    validateOnBlur: false,
  });

  useEffect(() => {
    const getMenu = async () => {
      try {
        const { data, status } = await get(`${urls.menu.getList}`);
        setMenuList(data.result.data);
        setList(
          data.result.data.map((m) => ({ label: m.name, value: m.name }))
        );
      } catch (error) {
        console.log(error);
      }
    };
    getMenu();
  }, []);

  const handleAddClick = () => {
    setInputList([...inputlist, { point: "" }]);
  };

  const handleAddClickBenifites = () => {
    setInputListBenifits([...inputlistBenifits, { point: "" }]);
  };

  const handleInputChange = (e, index) => {
    const { value } = e.target;
    const overviewList = [...inputlist];

    let newList = overviewList.map((l, i) =>
      i === index ? { ...l, point: value } : { ...l }
    );
    setInputList(newList);
  };

  const handleInputChangeBenifits = (e, index) => {
    const { value } = e.target;
    const benifitsList = [...inputlistBenifits];
    let newList = benifitsList.map((l, i) =>
      i === index ? { ...l, point: value } : { ...l }
    );
    setInputListBenifits(newList);
  };

  const handleRemoveClick = (i) => {
    const list = [...inputlist];
    list.splice(i, 1);
    setInputList(list);
  };
  const handleRemoveClickBenifits = (i) => {
    const list = [...inputlistBenifits];
    list.splice(i, 1);
    setInputListBenifits(list);
  };
  return (
    <>
      <form style={{ padding: "0 40px" }} onSubmit={formik.handleSubmit}>
        <Grid container spacing={2} sx={{ mb: 1 }} maxWidth="xl">
          <Grid
            container
            item
            xs={12}
            sm={12}
            md={12}
            display="flex"
            justifyContent="start"
            alignItems="center"
            textAlign="center"
          >
            <Button
              variant="text"
              size="medium"
              startIcon={<ArrowBackIcon />}
              onClick={() =>
                !setEditCourse
                  ? navigate(history ? history : "/products")
                  : setEditCourse(null)
              }
              sx={{ color: `${theme.palette.secondary.main}` }}
            >
              Back
            </Button>
            <Title sx={{ m: "15px" }}>
              {edit ? "Update Course" : "Add Course"}
            </Title>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Label htmlFor="">Category</Label>
            <br />
            <FormSelect
              formik={formik}
              name="menu"
              type="text"
              options={list}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Label htmlFor="productName">Course Name</Label>
            <br />
            <FormInput
              size="small"
              name="title"
              placeholder="course name"
              required
              fullWidth
              formik={formik}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Label htmlFor="pdu">PDU</Label>
            <br />
            <FormInput
              size="small"
              name="pdu"
              placeholder="pdu"
              fullWidth
              formik={formik}
            />
          </Grid>
          {/* <Grid item xs={12} sm={6} md={3}>
            <Label htmlFor="duration">Duration(in hrs)</Label>
            <br />
            <FormInput
              size="small"
              name="courseHours"
              type="number"
              placeholder="duration"
              required
              fullWidth
              formik={formik}
            />
          </Grid> */}

          <Grid item xs={12} sm={6} md={12}>
            {imageFile && (
              <>
                <img
                  src={imageFile}
                  alt="Flashcard"
                  style={{ width: "100px", marginBottom: "20px" }}
                />
                <br />
              </>
            )}

            <Button
              onClick={handleUploadButtonClick}
              variant="outlined"
              size="small"
              startIcon={<UploadFileIcon />}
            >
              Upload Thumbnail
            </Button>

            <input
              type="file"
              accept="image/*"
              name="thumbnailImage"
              onChange={handleFileChange}
              style={{ display: "none" }}
              ref={fileInputRef}
            />

            {formik.errors.thumbnailImage && (
              <Typography color="error" variant="caption" sx={{ mt: 1 }}>
                {formik.errors.thumbnailImage}
              </Typography>
            )}
          </Grid>

          <Grid item xs={12} sm={12} md={12} mt={1}>
            <Label htmlFor="description">Short Description</Label>

            <br />
            <FormInput
              size="small"
              name="description"
              placeholder="description"
              required
              fullWidth
              formik={formik}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12} sx={{ mb: 1.5 }}>
            <Label htmlFor="overview">Overview (Add points)</Label>
            <br />

            {inputlist.map((data, i) => (
              <Box
                key={i}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: "5px",
                }}
              >
                <KeyboardDoubleArrowRightIcon
                  fontSize="small"
                  sx={{ color: "#747DE8" }}
                />
                <TextField
                  size="small"
                  placeholder="overview"
                  fullWidth
                  // required
                  sx={{ mb: "5px", mt: 1 }}
                  value={data.point || ""}
                  name="overview"
                  inputProps={{
                    maxLength: 200,
                  }}
                  onChange={(e) => handleInputChange(e, i)}
                />

                <DeleteIcon
                  fontSize="small"
                  onClick={() => handleRemoveClick(i)}
                  color="error"
                />
              </Box>
            ))}

            <Button
              variant="contained"
              size="small"
              sx={{ mt: 1 }}
              onClick={handleAddClick}
            >
              <AddIcon />
            </Button>
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <Label htmlFor="benefits">
              What the students will learn (Add points)
            </Label>
            <br />
            {inputlistBenifits.map((data, i) => (
              <Box
                key={i}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: "5px",
                }}
              >
                <KeyboardDoubleArrowRightIcon
                  fontSize="small"
                  sx={{ color: "#747DE8" }}
                />
                <TextField
                  size="small"
                  placeholder="what you will learn"
                  sx={{ mb: "5px", mt: 1 }}
                  fullWidth
                  name="benefits"
                  value={data.point || ""}
                  inputProps={{
                    maxLength: 200,
                  }}
                  onChange={(e) => handleInputChangeBenifits(e, i)}
                />

                <DeleteIcon
                  fontSize="small"
                  color="error"
                  onClick={() => handleRemoveClickBenifits(i)}
                />
              </Box>
            ))}

            <Button
              variant="contained"
              size="small"
              sx={{ mt: 1 }}
              onClick={handleAddClickBenifites}
            >
              <AddIcon />
            </Button>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox checked={formik.values.isTrending} />}
                name="isTrending"
                onChange={(e) => {
                  formik.setFieldValue("isTrending", e.target.checked);
                }}
                label="This course is Trending?"
              />
            </FormGroup>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox checked={formik.values.isPaid} />}
                name="isPaid"
                onChange={(e) => {
                  formik.setFieldValue("isPaid", e.target.checked);
                }}
                label="This course is Paid Course?"
              />
            </FormGroup>
          </Grid>
          {formik.values.isPaid && (
            <Box sx={{ m: 1, p: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4}>
                  <InputLabel
                    htmlFor="standardPrice"
                    sx={{
                      fontSize: "14px",
                      fontWeight: "bold",
                      color: "#000000DE",
                    }}
                  >
                    Standard Price
                  </InputLabel>

                  <FormInput
                    size="small"
                    placeholder="Standard Price"
                    name="standardPrice"
                    formik={formik}
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <InputLabel
                    htmlFor="earlyBirdPrice"
                    sx={{
                      fontSize: "14px",
                      fontWeight: "bold",
                      color: "#000000DE",
                    }}
                  >
                    Early Bird Price
                  </InputLabel>

                  <FormInput
                    size="small"
                    name="earlyBirdPrice"
                    placeholder="Early Bird Price"
                    formik={formik}
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <InputLabel
                    htmlFor="earlyBirdEndDate"
                    sx={{
                      fontSize: "14px",
                      fontWeight: "bold",
                      color: "#000000DE",
                    }}
                  >
                    Early Bird End Date
                  </InputLabel>

                  <TextField
                    type="date"
                    size="small"
                    name="earlyBirdEndDate"
                    value={formik.values.earlyBirdEndDate}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.earlyBirdEndDate &&
                      Boolean(formik.errors.earlyBirdEndDate)
                    }
                    helperText={
                      formik.touched.earlyBirdEndDate &&
                      formik.errors.earlyBirdEndDate
                    }
                    required
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Box>
          )}
        </Grid>
        <Stack direction="row" spacing={2} marginBottom="30px" marginTop="30px">
          <Button
            variant="outlined"
            color="error"
            onClick={() =>
              !setEditCourse
                ? navigate(history ? history : "/products")
                : setEditCourse(null)
            }
          >
            Cancel
          </Button>
          <LoadingButton variant="contained" type="submit" loading={loading}>
            Submit
          </LoadingButton>
        </Stack>
      </form>
    </>
  );
}
