import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  Typography,
  styled,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import authentication from "../api/authendication";

import Stack from "@mui/material/Stack";
import { useFormik } from "formik";
import * as Yup from "yup";
import FormInput from "../components/FormInput";
import UserContext from "../context/UserContext";
import { LoadingButton } from "@mui/lab";

import towPiece from "../assets/Images/towpiece.png";

import laptop from "../assets/Images/laptop.png";

import logo from "../assets/Images/websaprucee.png";
import NavbarContext from "../context/NavbarContext";
import { RippelVideoButton } from "./RippelVideoButton";
import { theme } from "../theme";

const style = {
  height: "100vh",
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
};

const leftPart = {
  display: "flex",
  width: { xs: "100%", sm: "100%", md: "50%", lg: "40%" },
  height: "100%",
  backgroundImage: `url(${towPiece})`,
  backgroundRepeat: "no-repeat",
  backgroundPositionY: "100%",
};

const rightPart = {
  position: "relative",
  width: { xs: "0", sm: "65%", md: "65%", lg: "60%" },
  height: "100vh",
  display: { xs: "none", sm: "none", md: "block", lg: "block" },
  backgroundImage: `url(${laptop})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundPositionX: { md: "center", lg: "35px" },
  backgroundPositionY: "center",
  justifyContent: "end",
};

const inputForm = {
  width: { xs: "100%", sm: "100%", md: "60%" },
  margin: "20px auto",
};

const Login = () => {
  const { setUser } = useContext(UserContext);

  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const { setShowNavbar } = useContext(NavbarContext);
  const [rememberMe, setRememberMe] = useState(false);

  useEffect(() => {
    setShowNavbar(false);

    return () => setShowNavbar(true);
  }, []);

  const handleSubmit = async (value) => {
    setLoading(true);

    try {
      const data = {
        email: value.email,
        password: value.password,
        userType: "admin",
        rememberMe,
      };
      const loginRes = await authentication.login(data);
      window.localStorage.setItem(
        "user",
        JSON.stringify(loginRes.data.result.user)
      );
      setUser(loginRes.data.result.user);
      window.localStorage.setItem(
        "access_token",
        loginRes.data.result.access_token
      );
      window.location.href = "/menu";
      setLoading(false);
    } catch (error) {
      console.log("setResError", error);
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      userType: "admin",
    },
    validationSchema: Yup.object({
      email: Yup.string().email().required().label("Email").max(150),
      password: Yup.string().required().label("Password").max(32),
    }),
    onSubmit: async (values) => handleSubmit(values),
    validateOnBlur: false,
  });

  return (
    <>
      <Box sx={{ ...style, padding: { xs: "0 20px", sm: "0 20px", md: 0 } }}>
        <Box sx={leftPart}>
          <Box component="form" onSubmit={formik.handleSubmit} sx={inputForm}>
            <Stack sx={{ marginBottom: "40px" }}>
              <img
                src={logo}
                height={150}
                width="auto"
                style={{ objectFit: "contain", maxWidth: 450 }}
              />
            </Stack>
            <Grid container>
              <Grid item xs={12} sm={12} md={12} lg={12} mb={1}>
                <Typography
                  variant="h6"
                  sx={{
                    marginTop: "20px",
                    fontSize: "22px",
                    color: `${theme.palette.primary.main}`,
                    fontWeight: 700,
                    paddingLeft: "10px",
                  }}
                >
                  Admin Login
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <FormInput
                  formik={formik}
                  name="email"
                  type="email"
                  label="Enter your Email"
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} my={1}>
                <FormInput
                  formik={formik}
                  name="password"
                  type={showPassword ? "text" : "password"}
                  label="Enter Password"
                  size="small"
                  inputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {showPassword ? (
                          <IconButton
                            onClick={() => setShowPassword(!showPassword)}
                          >
                            <VisibilityOff fontSize="small" />
                          </IconButton>
                        ) : (
                          <IconButton
                            onClick={() => setShowPassword(!showPassword)}
                          >
                            <Visibility fontSize="small" />
                          </IconButton>
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox size="small" />}
                    type="checkbox"
                    onChange={(e) => {
                      setRememberMe(e.target.checked);
                    }}
                    sx={{ fontSize: "12px" }}
                    label="Remember Me"
                  />
                </FormGroup>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12}>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  loading={loading}
                  sx={{
                    width: "100%",
                    marginTop: "10px",
                  }}
                >
                  LogIn
                </LoadingButton>
              </Grid>
              <Link
                to="/forgot-password"
                style={{ textDecoration: "none", marginTop: "15px" }}
              >
                <Typography
                  variant="body"
                  textAlign="center"
                  sx={{
                    paddingLeft: "10px",
                    cursor: "pointer",

                    color: `${theme.palette.primary.main}`,
                    "&:hover": {
                      opacity: 0.8,
                    },
                  }}
                >
                  Forgot password
                </Typography>
              </Link>
            </Grid>
          </Box>
        </Box>

        <Box sx={rightPart}>
          <Box
            sx={{
              position: "absolute",
              margin: "auto",
              top: { md: "40%", lg: "40%" },
              left: { md: "26%", lg: "33%" },
              transform: "rotate(-20deg)",
            }}
          >
            <RippelVideoButton />
          </Box>
          {/* <img src={laptop} width="100%" height="100%" alt="Lodding..." /> */}
        </Box>
      </Box>
    </>
  );
};

export default Login;
