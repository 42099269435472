import React from "react";
import { Navigate } from "react-router-dom";

const PrivateRoute = ({ children }) => {
  const isAuth = window.localStorage.getItem("user");

  const navUser = () => {
    return <Navigate to="/" />;
  };
  return isAuth ? children : navUser();
};
export default PrivateRoute;
